import { ICommentItem, ICompany, IDashboardCardData, IHistoryItem, IPendingSubmissionAttachment, IPendingVersionListItem, IResponse, ISubmissionListItem, IUserProfile, IVersionListItem, IVersionStatus } from "Libraries/Interfaces";
import ApiService from "./ApiService";
import ApiUrls from "./ApiUrls";
import { AxiosResponse } from "axios";

class Request{
    public async signUpRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.signUpUrl, data, false);
        } catch (error) {
            throw error;
        }
    };
 
    public async signInRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.loginUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async refreshTokenRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.tokenRefreshUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async getSubmissionRequest(data: any, params: string): Promise<AxiosResponse<{ results: ISubmissionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionUrl + params, data);
        } catch (error) {
            throw error;
        }
    };

    public async getASubmissionRequest(controlNumber: string): Promise<AxiosResponse<ISubmissionListItem, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionUrl + `${controlNumber}/`, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getSubmissionVersionsRequest(params: string): Promise<AxiosResponse<{ results: IVersionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionVersionsUrl + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getPendingSubmissionVersions(params: string): Promise<AxiosResponse<{ results: IPendingVersionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.pendingVersions + params, undefined);            
        } catch (error) {
            throw error;
        }
    };

    public async getPendingSubmissionVersionAttachments(params: string): Promise<AxiosResponse<{ results: IPendingSubmissionAttachment[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.pendingVersionAttachments + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getCompaniesList(): Promise<AxiosResponse<{ results: ICompany[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.listCompanies, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionComments(params: string): Promise<AxiosResponse<ICommentItem[], any>> {
        try {
            return await ApiService.getRequest(ApiUrls.versionComments + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionHistories(params: string): Promise<AxiosResponse<{ results: IHistoryItem[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.fetchHistory + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionExposureFiles(params: string, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.exposureFiles + params, undefined);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async getVersionExposureTemplates(params: string, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.exposureTemplates + params, undefined);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async getDashboardCounts(params: string): Promise<AxiosResponse<IDashboardCardData, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.dashboardCounts + params, undefined);
        } catch (error) {
            throw error;
        }
    };

    public async getReportedConstructions(params: string, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.reportedConstructions + params, undefined);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async getReportedOccupancies(params: string, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.reportedOccupancies + params, undefined);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async generateInvoice(params: string, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.generateInvoice + params, undefined);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async getCompanies(callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.getCompanies, undefined);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async approvePendingVersion(data: { pending_submission_version_id: number; }, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.approvePendingVersion, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async approveAsControlNumber(data: { pending_submission_version_id: number; }, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.approveAsControlNumber, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async addVersionComments(data: { comment: string; submission_version_id: number; }, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.versionComments, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async updateStatus(data: IVersionStatus, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.updateStatus, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async archiveSubmission(data: { control_number: string; }, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.archiveSubmission, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async dublicateVersion(data: { control_number: string; version: number; }, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.dublicateVersion, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async emailReplyList(params: string, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.emailReplyList + params, undefined);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async getDTReportJson(params: string, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.getDTReportJson + params, undefined);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async archiveVersion(data: { control_number: string; versions: number[]; }, callback: (arg: IResponse) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.archiveVersion, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async resetPasswordTryRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.resetPasswordRequestUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async resetPasswordConfirmRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.resetPasswordConfirmUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async createLogRequest(data: any, callback: (arg: any, error?: any) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.createLogUrl, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, error: errMsg });
            }
        } catch (error) {
            callback('Error', error);
        }
    };

    public async sendUWEmail(data: any, callback: (arg: IResponse, error?: any) => void){
        try {
            const response = await ApiService.postRequestWithMultiPart(ApiUrls.uwEmail, data);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async changePasswordRequest(data: any, callback: (arg: any, error?: any) => void){
        try {
            const response = await ApiService.putRequest(ApiUrls.changePasswordUrl, data);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, error: errMsg });
            }
        } catch (error) {
            callback('Error', error);
        }
    };

    public async submissionAssignRequest(data: { submission_version_id: number; }, callback: (arg: IResponse, error?: any) => void){
        try {
            const response = await ApiService.putRequest(ApiUrls.submissionAssign, data);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async submissionUpdateRequest(data: any, callback: (arg: any, error?: any) => void){
        try {
            const response = await ApiService.putRequest(ApiUrls.submissionUpdate, data);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, error: errMsg });
            }
        } catch (error) {
            callback('Error', error);
        }
    };

    public async submissionGetRequest(data: any, callback: (arg: IResponse, error?: any) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.submissionGetUpdate, data);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error) {
            callback({ isSuccess: false, data: undefined, error });
        }
    };

    public async exportUserGetRquest(data: any, callback: (arg: any, error?: any) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.exportTemplate, data);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, error: errMsg });
            }
        } catch (error) {
            callback('Error', error);
        }
    };

    public async getSTPSovsLists(data: any, params: string, callback: (arg: any, error?: any) => void){
        try {
            const response = await ApiService.getRequest(ApiUrls.getSTPSovsLists + params, data);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, error: errMsg });
            }
        } catch (error) {
            callback('Error', error);
        }
    };

    public async getUserDetails(): Promise<{ data: IUserProfile | undefined; isSuccess: boolean; error: any; }> {
        try {
            const response = await ApiService.getRequest(ApiUrls.getUserDetails, {});
            const errMsg: any = response.data;

            if (response.status === 200 || response.status === 201) {
                return { data: response.data, isSuccess: true, error: undefined };
            } else {
                return { data: undefined, isSuccess: false, error: errMsg };
            }
        } catch (error) {
            return { data: undefined, isSuccess: false, error: error };
        }
    };

    public async postSovForSTPProcess(data: any, callback: (arg: IResponse, error?: any) => void){
        try {
            const response = await ApiService.postRequestWithMultiPart(ApiUrls.postSovForSTPProcess, data);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, data: undefined, error: errMsg });
            }
        } catch (error: any) {
            callback({ isSuccess: false, data: undefined, error: error?.response?.data?.message ?? error?.message });
        }
    };

    public async getConstOccPieChartData(data: any, callback: (arg: any, error?: any) => void){
        try {
            const response = await ApiService.postRequest(ApiUrls.constOccPieChartData, data, true);
            const errMsg: any = response.data;
            if (response.status === 200 || response.status === 201) {
                callback({ data: response.data, isSuccess: true });
            } else {
                callback({ isSuccess: false, error: errMsg });
            }
        } catch (error) {
            callback('Error', error);
        }
    };

    public async getPasswordRules(): Promise<AxiosResponse<{ password_rules: string[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getPasswordRules, undefined, true);
        } catch (error) {
            throw error;
        }
    };
}

const ApiRequest = new Request();

export default ApiRequest;
