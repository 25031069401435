import * as React from 'react';

import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";

import { IMapMarker } from "Libraries/Interfaces";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5geodata_usaLow from "@amcharts/amcharts5-geodata/usaLow";
import countries2 from "@amcharts/amcharts5-geodata/data/countries2";
import Methods from 'Libraries/CommonMethodsUI';
import { AiOutlineExpand } from "react-icons/ai";
import Constants from 'Libraries/Constants';

const HeatMap: React.FC<{ 
    markers: IMapMarker[]; isUpdated: number;
}> = ({ markers, isUpdated }) => {
  
    React.useEffect(() => {
        loadVisualizationLibrary()
        .then(() => {
            onInitialLoad();
        })
        .catch(() => {});
    }, [isUpdated]);

    const loadVisualizationLibrary = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=visualization`;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    };
    
    const onInitialLoad = (): void => {
        const filterEmptyLatLng = markers.filter(mark => mark.lat);

        // findout max and min latLong coordinates 
        const latlist = filterEmptyLatLng.map((item: any) => item?.lat);
        const lnglist = filterEmptyLatLng.map((item: any) => item?.lng);
        let maxlat = Math.max(...latlist);
        let minlat = Math.min(...latlist);
        let maxlng = Math.max(...lnglist);
        let minlng = Math.min(...lnglist);
        // set initial zoom of Google map based on max and min coordinates
        const zoom = getZoom(maxlat, maxlng, minlat, minlng);

        var mapCenter = new google.maps.LatLng(minlat, maxlng);

        const map = new google.maps.Map(document.getElementById('google-heatmap') as any, {
            center: mapCenter,
            zoom: zoom,
            mapTypeId: 'satellite',
            fullscreenControl: true,
            zoomControl: true,
            panControl: true,
            minZoom: 2,
        });

        const bounds = new google.maps.LatLngBounds();
        const heatMapData = markers.map((v) => {
            const location = new google.maps.LatLng(v.lat, v.lng);
            bounds.extend(location);
            return { location, weight: 1 };
        });

        var heatmap = new google.maps.visualization.HeatmapLayer({
            data: heatMapData
        });
    
        map.fitBounds(bounds);
        heatmap.setMap(map);
    };

    const latRad = (lat: number): number => {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    };

    const getZoom = (lat_a: number, lng_a: number, lat_b: number, lng_b: number): number => {
        let latDif = Math.abs(latRad(lat_a) - latRad(lat_b));
        let lngDif = Math.abs(lng_a - lng_b);
    
        let latFrac = latDif / Math.PI;
        let lngFrac = lngDif / 360;
    
        let lngZoom = Math.log(1 / latFrac) / Math.log(2);
        let latZoom = Math.log(1 / lngFrac) / Math.log(2);
        return lngZoom > latZoom ? latZoom + 2 : lngZoom + 2;
    };

    return (
        <div className={`w-full relative h-[82vh] heatmap-container`} id="google-heatmap"></div>
    );
};

export const ChoroplethMap: React.FC<{ 
    markers: IMapMarker[]; styles?: any; id: string;
}> = ({ id, markers, styles }) => {

    React.useLayoutEffect(() => {

        if (!document.getElementById(id as any)) {
            return;
        }
  
        let root = am5.Root.new(id);
    
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
  
        // Create chart
        var chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "rotateX",
            panY: "none",
            projection: am5map.geoAlbersUsa(),
            layout: root.horizontalLayout
        }));
        
        // Create polygon series
        var polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_usaLow,
            valueField: "value",
            calculateAggregates: true
        }));
        
        polygonSeries.mapPolygons.template.setAll({
            tooltipText: "{name}: {value}"
        });
        
        polygonSeries.set("heatRules", [{
            target: polygonSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color(0xff621f),
            max: am5.color(0x661f00),
            key: "fill"
        }]);
        
        polygonSeries.mapPolygons.template.events.on("pointerover", function(ev: any) {
            heatLegend.showValue(ev.target.dataItem.get("value"));
        });
  
        polygonSeries.data.setAll(markers.map((v: any) => {
            return { id: v?.countryCode ? `${v?.countryCode?.[0]}-${v.state[0]}` : `US-${v.state[0]}`, value: Math.round(Methods.arrayValuesSum(v.coverages)) };
        }));
  
        var heatLegend = chart.children.push(am5.HeatLegend.new(root, {
            orientation: "vertical",
            startColor: am5.color(0xff621f),
            endColor: am5.color(0x661f00),
            startText: "Lowest",
            endText: "Highest",
            stepCount: 5
        }));
        
        heatLegend.startLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("startColor")
        });
        
        heatLegend.endLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("endColor")
        });
        
        // change this to template when possible
        polygonSeries.events.on("datavalidated", function () {
            heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
            heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
        });
      
        return () => {
            root.dispose();
        };
  
    }, [id]);
  
    return (
      <div id={id} className="relative" style={styles}>
        <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
      </div>
    );
};

export const ChoroplethHeatMap: React.FC<{ 
    markers: IMapMarker[]; styles?: any; id: string; countyMatrix: any[];
}> = ({ id, markers, styles, countyMatrix }) => {

    const [rootCopy, setRootCopy] = React.useState<any>();
    const [isStateEnable, setIsStateEnable] = React.useState<boolean>(false);
    
    function getUniqueIdsWithSum(arr: any[]) {
        return Object.values(
            arr.reduce((acc: any, obj: any) => {
                if (!acc[obj.id]) {
                    acc[obj.id] = { ...obj }; // Copy the object to avoid mutating the original
                } else {
                    acc[obj.id].value += obj.value; // Sum the value field
                }
                return acc;
            }, {})
        );
    }

    React.useLayoutEffect(() => {
  
        if (!document.getElementById(id as any)) {
            return;
        }
  
        let root = am5.Root.new(id);
        let colors = am5.ColorSet.new(root, {});
    
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
  
        /* Chart code */
        let continents: any = { "AF": 0, "AN": 1, "AS": 2, "EU": 3, "NA": 4, "OC": 5, "SA": 6 };

        // Create the map chart
        // https://www.amcharts.com/docs/v5/charts/map-chart/
        let chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "rotateX",
            projection: am5map.geoMercator(),
            layout: root.horizontalLayout
        }));
        
        // Create polygon series for the world map
        let worldSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_worldLow,
            exclude: ["AQ"]
        }));
        
        worldSeries.mapPolygons.template.setAll({
            tooltipText: "{name}",
            interactive: true,
            fill: am5.color(0xaaaaaa),
            templateField: "polygonSettings"
        });
        
        worldSeries.mapPolygons.template.states.create("hover", {
            fill: colors.getIndex(9)
        });

        // Country polygon series
        var countrySeries: any = chart.series.push(am5map.MapPolygonSeries.new(root, {
            valueField: "value",
            calculateAggregates: true,
            visible: false
        }));

        countrySeries.mapPolygons.template.setAll({
            tooltipText: "{name}: {value}"
        });
        
        countrySeries.set("heatRules", [{
            target: countrySeries.mapPolygons.template,
            dataField: "value",
            min: am5.color("#27C95C"),
            max: am5.color("#f5b61b"),
            key: "fill"
        }]);
        
        countrySeries.mapPolygons.template.events.on("pointerover", function(ev: any) {
            heatLegend.showValue(ev.target.dataItem.get("value"));
        });

        let stateSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            valueField: "value",
            calculateAggregates: true,
            visible: false
        }));
          
        stateSeries.mapPolygons.template.setAll({
            tooltipText: "{name}: {value}"
        });

        stateSeries.set("heatRules", [{
            target: stateSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color("#f1f1f1"),
            max: am5.color("#f5b61b"),
            key: "fill"
        }]);

        stateSeries.mapPolygons.template.events.on("pointerover", function(ev: any) {
            heatLegend.showValue(ev.target.dataItem.get("value"));
        });

        const updatedData: any[] = getUniqueIdsWithSum(markers.map((v: any) => {
            return { 
                id: v?.countryCode ? `${v?.countryCode?.[0]?.toString()?.trim()}-${v?.state?.[0]?.toString()?.trim()}` : `US-${v?.state?.[0]?.toString()}`, 
                referenceId: v?.countryCode ? v?.countryCode?.[0]?.toString() : "US",
                value: Math.round(Methods.arrayValuesSum(v?.coverages)), state: v?.state?.[0]?.toString(),
            };
        }));
        
        worldSeries.mapPolygons.template.events.on("click", (ev: any) => {
            let dataItem = ev.target.dataItem;
            let data = dataItem.dataContext;
            let zoomAnimation: any = worldSeries.zoomToDataItem(dataItem);
        
            Promise.all([
                zoomAnimation.waitForStop(),
                am5.net.load("https://cdn.amcharts.com/lib/5/geodata/json/" + data.map + ".json", chart)
            ]).then((results: any) => {
                let geodata = am5.JSONParser.parse(results[1].response);

                countrySeries.setAll({
                    geoJSON: geodata,
                    fill: am5.color('#f1f1f1'),
                });
                title.set("text", data?.name);

                countrySeries.data.setAll(updatedData.filter((f) => f.referenceId === data.id).map((v) => {
                    return { ...v, id: geodata.features.find((f: any) => f.properties.name.toLowerCase() === v.state.toLowerCase())?.properties?.id ?? v.id }
                }));
            
                countrySeries.show();
                heatLegend.show();
                worldSeries.hide(100);
                backContainer.show();
                setIsStateEnable(true);
                title.show();
            });
        });

        countrySeries.mapPolygons.template.events.on("click", (ev: any) => {
            var dataItem = ev.target.dataItem;
            var id = dataItem.get("id").toLowerCase().split("-").pop();
            var name = dataItem.dataContext.name;
            var country: string | any = Constants.amchartCountryVerifyForCountyMap.find(f => f.match === dataItem.dataContext?.CNTRY)?.add;
            var zoomAnimation = countrySeries.zoomToDataItem(dataItem);
          
            if (!country) {
                return;
            }

            Promise.all([
              zoomAnimation.waitForStop(),
              am5.net.load(`https://cdn.amcharts.com/lib/5/geodata/json/region/${country}/` + id + "Low.json", chart)
            ]).then(function(results: any) {
                var geodata = am5.JSONParser.parse(results[1].response);

                stateSeries.setAll({
                    geoJSON: geodata,
                    fill: am5.color('#f1f1f1'),
                });
                
                stateSeries.data.setAll(geodata.features.map((v: any) => {
                    const c = countyMatrix?.find(f => f?.label.toLowerCase() === v.properties?.name.toLowerCase());
                    return { 
                        ...v.properties, 
                        value: c ? Methods.arrayValuesSum(Object.values(Methods.sumOfArrayObjectsForMappings([c])).flat(1)) : 0,
                    }
                }));
                      
                stateSeries.show();
                heatLegend.show();
                countrySeries.hide(100);
                worldSeries.hide();
                backContainer.show();
                title.set("text", name);
            }).catch((e) => {
                console.error(e);
            });
        });
  
        var heatLegend = chart.children.push(am5.HeatLegend.new(root, {
            orientation: "vertical",
            startColor: am5.color("#27C95C"),
            endColor: am5.color("#f5b61b"),
            startText: "Lowest",
            endText: "Highest",
            stepCount: 5,
            visible: false
        }));
        
        heatLegend.startLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("startColor")
        });
        
        heatLegend.endLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("endColor")
        });
        
        // change this to template when possible
        countrySeries.events.on("datavalidated", function () {
            heatLegend.set("startValue", countrySeries.getPrivate("valueLow"));
            heatLegend.set("endValue", countrySeries.getPrivate("valueHigh"));
        });
        stateSeries.events.on("datavalidated", function () {
            heatLegend.set("startValue", stateSeries.getPrivate("valueLow"));
            heatLegend.set("endValue", stateSeries.getPrivate("valueHigh"));
        });

        // Set up data for countries
        let data = [];
        for(const cid in countries2) {
            if (countries2.hasOwnProperty(cid)) {
                let country = countries2[cid];
                if (country.maps.length) {
                    data.push({
                        id: cid,
                        map: country.maps[0],
                        polygonSettings: {
                            fill: updatedData.filter((f) => f.referenceId === cid).length > 0 ? colors.getIndex(continents[country.continent_code]) :"#f1f1f1",
                        }
                    });
                }
            }
        }
        worldSeries.data.setAll(data);  
  
        // Add button to go back to continents view
        let backContainer = chart.children.push(am5.Container.new(root, {
            x: am5.p100,
            centerX: am5.p100,
            dx: -75,
            paddingLeft: 1,
            paddingTop: 1,
            paddingRight: 1,
            paddingBottom: 1,
            y: 2,
            interactiveChildren: false,
            position: 'absolute',
            cursorOverStyle: "pointer",
            background: am5.RoundedRectangle.new(root, {
            fill: am5.color("#f1f1f1"),
            fillOpacity: 1
            }),
            visible: false
        }));

        backContainer.children.push(am5.Label.new(root, {
            text: "Reset",
            fill: am5.color(0x555555),
            fontSize: 12,
        }));

        backContainer.events.on("click", function() {
            chart.goHome();
            worldSeries.show();
            countrySeries.hide();
            backContainer.hide();
            heatLegend.hide();
            stateSeries.hide();
            title.hide();
            setIsStateEnable(false);
        });

        var title = chart.children.push(am5.Label.new(root, {
            text: "",
            x: am5.p50,
            y: 3,
            position: 'absolute',
            fontSize: 15,
            textAlign: "center",
            visible: false
        }));

        setRootCopy(root);

        return () => {
            root.dispose();
        };
  
    }, [id, markers, countyMatrix]);

    const onExpand = () => {
        if (!document.fullscreenElement) {
            rootCopy.dom.classList.add('bg-white');
            rootCopy.dom.requestFullscreen().catch((err: any) => {
              alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
    };
  
    const topProperty = !document.fullscreenElement ? `${isStateEnable ? 'top-8 right-5' : 'top-0 right-0'}` : `${isStateEnable ? 'top-8 right-5' : 'top-2 right-2'}`;
    return (
      <div id={id} className="relative" style={styles}>
        <div onClick={onExpand} className={`absolute z-20 transition-all duration-300 ease-in-out ${topProperty} bg-[#f1f1f1] cursor-pointer rounded-sm w-10 h-10 flex flex-col items-center justify-center`}>
            <AiOutlineExpand size={25} />
        </div>
        <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
      </div>
    );
};

export default HeatMap;