import * as React from 'react';

import withAuthentication from "Hoc/WithAuthentication";

import { Paths } from "Libraries/Route";
import MasterLayout from 'Hoc/MasterLayout';
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import { Images } from 'Libraries/Images';
import DateTimeRangePicker, { ISelectionRange } from 'Components/DateRange/DateRange';
import { MyCombobox } from 'Components/ScreenFilters/ScreenFilters';
import ApiRequest from 'Services/ApiRequest';
import { useReactToPrint } from 'react-to-print';
import Methods from 'Libraries/CommonMethodsUI';
import { useGenerateCompanyInvoice } from 'Hooks/UseFetch';

const Invoice = ({ user }: any) => {

    const reportTemplateRef = React.useRef<HTMLDivElement>();
    const selectionRangeInitial = { startDate: undefined, endDate: undefined, key: 'selection' };
    const border = { borderRight: '2px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgba(224, 224, 224, 1)' };

    const [params, setParams] = React.useState<string>('');
    const [companies, setCompanies] = React.useState<string[]>([]);
    const [selectedCompany, setSelectedCompany] = React.useState<string>('');
    const [selectionRange, setSelectionRange] = React.useState<ISelectionRange>(selectionRangeInitial);

    React.useEffect(() => {
        ApiRequest.getCompanies((res) => {
            setCompanies(res.data?.company_names ?? []);
        });
    }, []);

    const { companyInvoice, resetHookState } = useGenerateCompanyInvoice(params);

    const generatePDF = useReactToPrint({
        content: () => reportTemplateRef.current as any,
        documentTitle: `Invoice for ${selectedCompany} by ${Methods.getDateByFormat(selectionRange?.startDate as any, 'YYYY-MM-DD')}/${Methods.getDateByFormat(selectionRange?.endDate as any, 'YYYY-MM-DD')}`        
    })

    const generateInvoice = React.useCallback(() => {
        if (selectedCompany && selectionRange?.startDate && selectionRange?.endDate) {
            const SD = Methods.getDateByFormat(selectionRange?.startDate as any, 'YYYY-MM-DD');
            const ED = Methods.getDateByFormat(selectionRange?.endDate as any, 'YYYY-MM-DD');

            setParams(`?from_date=${SD}&to_date=${ED}&company_name=${selectedCompany}`);
        }
    }, [selectedCompany, selectionRange]);

    const counts = companyInvoice?.list_data?.totals;
    const invoiceFrom = companyInvoice?.invoice_details?.invoice_from;
    const invoiceFor = companyInvoice?.invoice_details?.invoice_for;
    const invoiceServicesList = companyInvoice?.list_data?.service ?? [];
    const invoiceProductsList = companyInvoice?.list_data?.product ?? [];

    return (
        <MasterLayout mainTitle='Invoice' className='px-4 sm:px-6 lg:px-8' mainClassName='bg-[#F5F5F5] overflow-y-auto remove-scrollbar' userEmail={user?.email as string}>
            <DocumentTitle title='Invoice'>
                <div className='flex items-center justify-end mb-8'>
                    <div className='mr-auto inline-flex items-center justify-start'>
                        <DateTimeRangePicker
                            setSelectionRange={(arg: ISelectionRange | undefined) => {
                                if (arg !== undefined) {
                                    setSelectionRange(arg);
                                } else {
                                    setSelectionRange(selectionRangeInitial);
                                }
                            }}
                            selectionRange={selectionRange}
                        />
                        <MyCombobox isReset={false} className="ml-4" placeholder="Select Company" arr={companies} getSelected={(s) => setSelectedCompany(s)} />
                    </div>
                    {['Reset', 'Generate Invoice', 'Export PDF'].map((btn: string, i: number) => (
                        <button key={btn} className='px-4 py-1.5 inline-flex items-center hover:bg-opacity-60 transition-all duration-200 justify-center h-6 rounded-full ml-4 bg-primary-blue-dark text-xs text-white' onClick={() => {
                            if (i === 0) {
                                setSelectionRange(selectionRangeInitial);
                                setSelectedCompany('');
                                resetHookState();
                            }
                            else if (i === 1) {
                                generateInvoice();
                            } 
                            else {
                                generatePDF();
                            }
                        }}>{btn}</button>
                    ))}
                </div>
                <div className="rounded-xl bg-white w-full pt-8 pb-3 flex flex-col" ref={reportTemplateRef as any}>
                    <div className="flex flex-row justify-between items-center px-20">
                        <div className="py-1 pl-2.5 flex-1">
                            <img alt="logo" src={Images.logo} className="img-fluid" width="100px" height="106px" />
                        </div>
                        <div className='flex flex-col justify-end w-[300px]'>
                            <h5 className='font-bold mb-3 text-center'>INVOICE</h5>
                            <div className="grid grid-cols-2 divide-x-2 divide-[#D9D9D9]">
                                <div className='pr-3 text-sm w-auto text-right'>From</div>
                                <div className='pl-3 flex flex-col justify-start'>
                                    <span className='text-base font-bold'> {invoiceFrom?.address_line1 ?? ""} </span>
                                    {[invoiceFrom?.address_line2 ?? "", invoiceFrom?.address_line3 ?? "", invoiceFrom?.address_line4 ?? ""].map((str, i: number) => (
                                        <span className='font-normal text-black text-sm' key={i}> {str} </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="flex flex-row justify-between items-center pr-20 pl-16">
                        <div className="flex flex-col justify-start relative">
                            <div className="h-full left-[84px] w-[1.5px] bg-[#D9D9D9] rounded absolute"></div>
                            <div className="inline-flex items-center justify-start mb-1">
                                <h5 className='font-bold text-lg w-24'>INVOICE</h5>
                                <h5 className='font-bold text-lg'>{companyInvoice?.invoice_details?.invoice_id?.toString() ?? "N/A"}</h5>
                            </div>
                            {[
                                { caption: 'Issue Date', value: companyInvoice?.invoice_details?.issue_date ?? ''},
                                { caption: 'Due Date', value: companyInvoice?.invoice_details?.due_date ?? ''},
                                { caption: 'Subject', value: companyInvoice?.invoice_details?.subject ?? ""}
                            ].map((item, i: number) => (
                                <div className="inline-flex items-center justify-start mb-0.5" key={i}>
                                    <div className="text-xs text-black font-normal w-24">{item.caption}</div>
                                    <div className="text-xs text-black font-normal">{item.value}</div>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-col justify-end w-[300px]'>
                            <div className="grid grid-cols-2 divide-x-2 divide-[#D9D9D9]">
                                <div className='pr-3 text-sm w-auto text-right'>Invoice For</div>
                                <div className='pl-3 flex flex-col justify-start'>
                                    <span className='text-base font-bold'> {invoiceFrom?.address_line1 ?? ""} </span>
                                    {[invoiceFor?.address_line2 ?? "", invoiceFor?.address_line3 ?? "", invoiceFor?.address_line4 ?? ""].map((str, i: number) => (
                                        <span className='font-normal text-black text-sm' key={i}> {str} </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    <table className="mx-16">
                        <thead>
                            <tr>
                                <td width="85px" className={`py-2 whitespace-nowrap text-center text-xs font-semibold text-black px-3`} style={{ ...border }}>
                                    Item Type
                                </td>
                                <td width="60%" className={`py-2 whitespace-nowrap text-left text-xs font-semibold text-black px-4`} style={{ ...border }}>
                                    Description
                                </td>
                                <td className={`py-2 whitespace-nowrap text-left text-xs font-semibold text-black px-4 text-right`} style={{ ...border }}>
                                    Quantity
                                </td>
                                <td className={`py-2 whitespace-nowrap text-left text-xs font-semibold text-black px-4 text-right`} style={{ ...border }}>
                                    Unit Price	
                                </td>
                                <td className={`py-2 whitespace-nowrap text-left text-xs font-semibold text-black px-4 text-right`} style={{ borderBottom: border.borderBottom }}>
                                    Amount
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {[...invoiceServicesList, ...invoiceProductsList].map((item, i: number) => {
                                return <tr key={i} style={{ background: i % 2 !== 0 ? 'whitesmoke' : 'transparent' }} className='rounded'>
                                    {[`${invoiceServicesList.length > i ? "Service" : "Product"}`, item.description, item?.quantity, `$ ${item?.unit_price}`, `$ ${item?.amount}`].map((str, ind: number) => (
                                        <td width={ind === 1 ? "60%" : "auto"} style={ind < 4 ? { ...border } : { borderBottom: '2px solid rgba(224, 224, 224, 1)' }} 
                                            key={str} className={`whitespace-nowrap py-2 text-xs text-black px-4 ${ind === 0 ? 'text-center' : ind === 1 ? ' text-left' : 'text-right'}`}
                                        >
                                            {str}
                                        </td>
                                    ))}
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <br />

                    <div className='pr-1 flex flex-col pr-12'>
                        { [
                            { caption: 'Subtotal', value: counts?.sub_total ?? 0 },
                            { caption: `Discount (${counts?.discount_percentage ?? 0}%)`, value: counts?.discount ?? 0 },
                            { caption: `Tax (${counts?.tax_percentage ?? 0}%)`, value: counts?.tax ?? 0 }
                        ].map(({ caption, value }, ind: number) => (
                            <div key={ind} className="flex flex-row items-center justify-end text-sm mb-1">
                                <div className='text-right min-w-[80px]'>
                                    {caption}
                                </div>
                                <div className='text-right min-w-[120px] font-bold'>
                                    ${value}
                                </div>                                
                            </div>
                        )) }       
                        <br />

                        <div className="flex flex-row items-center justify-end text-xl">
                            <h6 className="mr-2.5 font-bold min-w-[100px]">Amount Due</h6>
                            <h6 className="font-bold min-w-[108px] text-right">${ counts?.amount_due ?? 0 }</h6>
                        </div>                 
                    </div>

                    <div className='flex flex-col font-semibold text-xs mx-auto w-11/12'>
                        <span className='block mb-1'>Make all checks payable to VINAR LLC.</span>
                        <span className='block mb-1'>THANK YOU FOR YOUR BUSINESS!</span>
                    </div>
                </div>
            </DocumentTitle>
        </MasterLayout>
    )
};

export default withAuthentication(Invoice, Paths.login, 9);
