import * as React from 'react';

interface IProps {
    tabs: { id: number; name: string; }[];
    active: number;
    onChange: (id: number) => void;
    activeClassName: string;
    inActiveClassName: string;
}

export const Tabs: React.FC<IProps> = ({ active, tabs, onChange, activeClassName, inActiveClassName }) => {
    return (
        <div className="flex flex-row items-center justify-start">
            {tabs.map((tab) => (
                <span
                    onClick={() => onChange(tab.id)} key={tab.name}
                    className={`py-1 transition-all duration-150 focus:outline-none cursor-pointer ${ active === tab.id ? `${activeClassName}` : `text-primary-dark opacity-40 ${inActiveClassName}` }`}
                >{
                    tab.name}
                </span>
            ))}
        </div>
    );
}
