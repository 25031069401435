import * as React from 'react';

import { Images } from 'Libraries/Images';
import { HiOutlineMail } from 'react-icons/hi';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

interface IProps {
    email: string | undefined; 
    backForward: boolean;
    children: JSX.Element;
}

export default function AuthWrapper(props: IProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <div 
            className="flex flex-col h-screen w-full relative bg-primary-blue" 
            style={{ 
                backgroundImage: `url(${Images.loginBg})`, backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat', backgroundOrigin: 'content-box', backgroundPosition: '100% 100%',
            }}
        >
            <div 
                className="flex flex-col h-[61px] w-full fixed px-12 justify-center" 
                style={{
                    background: 'linear-gradient(270deg, #070A0F 0%, #132E63 45.31%, #000 100%)'
                    // boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)'
                }}
            >
                <div className='flex flex-row w-full items-center justify-between'>
                    <img alt="logo" src={Images.completeLogo} className='flex-shrink-0' width="160px" />
                    { props.email && (
                        <div className="items-start flex text-white">
                            <HiOutlineMail className="mr-3 text-2xl my-0" />
                            <span className="font-normal text-sm">{props.email}</span>
                        </div>
                    )}                    
                </div>
            </div>
            <div className='relative m-auto flex flex-col bg-primary-blue xl:py-8 xl:px-16 lg:py-8 lg:px-16 md:py-6 md:px-12 py-4 px-8 rounded-2xl xl:w-2/5 lg:w-1/2 w-11/12'>
                { props.backForward && (<BiArrowBack onClick={() => navigate(-1)} className="absolute text-white text-lg cursor-pointer left-6 top-6 hover:opacity-60 transition-all duration-200" /> )}
                { props.children }
            </div>
        </div>
    );
}