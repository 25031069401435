import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { PolarArea, Pie } from "react-chartjs-2";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import { activeOneSliceRemainingInActive, circularLabelsInsideLargeWidthSlice } from "./AmFunctions";
import Constants from "Libraries/Constants";
import Methods from "Libraries/CommonMethodsUI";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, Title);

export const data = {
  labels: [],
  datasets: [],
};

const options = {
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: true,
      labels: { 
        font: { size: 15 } 
      }
    },
  },
};

const PieChart = ({ data }: { data: any }) => {
  let dataSet = {
    labels: ["Building", "Other", "Contents", "BI"],
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgba(246, 212, 88, 0.8)",
          "rgba(78, 129, 188, 0.8)",
          "rgba(9, 145, 205, 0.8)",
          "rgba(239, 80, 23, 0.8)",
        ],
        borderColor: [
          '#f6d458',
          '#4e81bc',
          '#0991cd',
          '#f6d458',
        ],
        borderWidth: 1,
      },
    ],
  };

  return <PolarArea className="polararea-charts" data={dataSet} options={options} />;
};

export const CustomPieChart: React.FC<{ labels: string[]; data: number[]; colors: string[]; }> = ({ labels, data, colors }) => {
  return (
    <Pie className="pie-charts" data={{
      labels: labels,
      datasets: [
        {
          data: data.length > 0 ? data : [0, 0, 0, 0],
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        },
      ],
    }} options={options} />
  );
}

export const AMPieChart: React.FC<{ 
  id: string; labels: string[]; data: number[]; colors: string[]; styles: any; isLabelsVisible?: boolean; isTitle?: string;
  onFirstClick?: (category: string) => void; tooltipAsPercentage?: boolean;
}> = ({ id, labels, data, colors, styles, isLabelsVisible, isTitle, onFirstClick, tooltipAsPercentage }) => {

  React.useLayoutEffect(() => {

    if (!document.getElementById(id as any)) {
      return;
    }
    
    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout
    }));
    
    // Create series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "count",
      categoryField: "category",
      alignLabels: false,
      calculateAggregates: true,
    }));

    if (colors.length > 0) {
      const seriesColors: am5.ColorSet | undefined | any = series.get("colors");
      seriesColors.set("colors", colors.map((c) => am5.Color.fromAny(c)));
    }
    
    activeOneSliceRemainingInActive(series);
    circularLabelsInsideLargeWidthSlice(series, 6);

    // Set data
    series.data.setAll(
      labels.map((l, i) => { return { category: l, count: data[i] }; }
    ));

    series.labels.template.setAll({
      text: "{category}",
      textType: "circular",
      inside: true,
      fontSize: 12,
      maxWidth: 90,
      oversizedBehavior: "truncate"
    });

    // Hide labels and ticks
    // series.labels.template.set("visible", false);
    // series.ticks.template.set("visible", false);
    
    if (isLabelsVisible) {
      // Create legend
      let legend = chart.children.unshift(am5.Legend.new(root, {
        centerX: am5.percent(2),
        x: am5.percent(2),
        marginTop: 0,
        marginBottom: 0,
        layout: root.horizontalLayout,
        width: am5.percent(100),
      }));  

      // Customize legend labels to show only category
      legend.labels.template.setAll({
        text: "{category}: {count}",
        populateText: true
      });
      
      legend.labels.template.setAll({
        fontSize: 10, // Reduce the font size
        textAlign: "center", // Center-align the text
      });

      legend.valueLabels.template.setAll({
        // text: "",
        fontSize: 10, // Reduce the font size
        // visible: false,
        // disabled: true
      });
      
      legend.data.setAll(series.dataItems);
    }
        
    // Play initial series animation
    series.slices.template.set("tooltipText", "{category}: {count}");
    series.slices.template.set("tooltip", am5.Tooltip.new(root, {
      labelText: `[fontSize: 12px]{category}: {count}${tooltipAsPercentage ? '%' : ''}[/]`
    }));
    series.appear(1000, 100);

    series.events.on("click", function(ev) {
      series.slices.each(function(slice: any) {
        if (slice.get("active")) {
          if (onFirstClick) {
            onFirstClick(slice._dataItem?.dataContext.category);
          }
        }
      })
    });
    
    return () => {
      root.dispose();
    };
  }, [id]);

  return (
    <div id={id} className="relative text-center" style={styles}>
      { isTitle && (
        <span onClick={() => onFirstClick && onFirstClick(isTitle as string)} className={`font-semibold text-xs text-black ${onFirstClick && 'cursor-pointer'}`}>{isTitle}</span>
      )}
      <div className={`absolute bg-white z-20 w-[66px] h-[19px] left-0 ${isTitle ? 'bottom-[-20px]' : 'bottom-0'} rounded-2xl`}></div>
    </div>
  );
};

export const AmPieOfPieChart: React.FC<{
  data: { mappings: string; percentage: number; subOccData: { mappings: string; percentage: number; }[]; }[];
  selectedCategory: string; styles: any; id: string; isLarge: boolean; isUpdation?: number;
}> = ({ data, selectedCategory, styles, id, isLarge, isUpdation }) => {

  React.useLayoutEffect(() => {
    if (!document.getElementById(id as any)) {
      return;
    }

    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layout: root.horizontalLayout
      })
    );
    
    // Create main chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = container.children.push(
      am5percent.PieChart.new(root, {
        radius: isLarge ? am5.percent(90) : am5.percent(85),
        tooltip: am5.Tooltip.new(root, {})
      })
    );
    
    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
      })
    );

    circularLabelsInsideLargeWidthSlice(series, 6);

    // Hide labels and ticks
    // series.labels.template.set("visible", false);
    // series.ticks.template.set("visible", false);
    
    series.labels.template.setAll({
      textType: "circular",
      radius: 4,
      orientation: "outward",
      inside: false,
      fontSize: isLarge ? 14 : 12,
      maxWidth: isLarge ? 150 : 90,
      oversizedBehavior: "truncate"
    });
    // series.ticks.template.set("visible", false);
    series.slices.template.set("toggleKey", "none");
    
    // add events
    series.slices.template.events.on("click", function(e) {
      selectSlice(e.target);
    });
    
    // Create sub chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let subChart = container.children.push(
      am5percent.PieChart.new(root, {
        radius: isLarge ? am5.percent(65) : am5.percent(50),
        tooltip: am5.Tooltip.new(root, {})
      })
    );
    
    // Create sub series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let subSeries = subChart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false
      })
    );

    circularLabelsInsideLargeWidthSlice(subSeries, 4);

    subSeries.labels.template.setAll({
      textType: "circular",
      orientation: "outward",
      inside: false,
      fontSize: isLarge ? 12 : 10,
      maxWidth: isLarge ? 100 : 50,
      oversizedBehavior: "truncate"
    });

    // Hide labels and ticks
    // subSeries.labels.template.set("visible", false);
    // subSeries.ticks.template.set("visible", false);
    
    subSeries.data.setAll(data.map((v) => v.subOccData).flat(2).map((v) => {
      return { category: v.mappings, value: v.percentage };
    }));
    subSeries.slices.template.set("tooltip", am5.Tooltip.new(root, {
      labelText: "[fontSize: 12px]{category}: {value} %[/]"
    }));
    subSeries.slices.template.set("toggleKey", "none");
    
    let selectedSlice: any;
    
    series.on("startAngle", function() {
      updateLines();
    });
    
    container.events.on("boundschanged", function() {
      root.events.once("frameended", function() {
        updateLines();
       })
    });
    
    function updateLines() {
      if (selectedSlice) {
        let startAngle = selectedSlice.get("startAngle");
        let arc = selectedSlice.get("arc");
        let radius = selectedSlice.get("radius");
    
        let x00 = radius * am5.math.cos(startAngle);
        let y00 = radius * am5.math.sin(startAngle);
    
        let x10 = radius * am5.math.cos(startAngle + arc);
        let y10 = radius * am5.math.sin(startAngle + arc);
    
        let subRadius: number | any = (subSeries.slices.getIndex(0) as any).get("radius");
        let x01 = 0;
        let y01 = -subRadius;
    
        let x11 = 0;
        let y11 = subRadius;
    
        let point00 = series.toGlobal({ x: x00, y: y00 });
        let point10 = series.toGlobal({ x: x10, y: y10 });
    
        let point01 = subSeries.toGlobal({ x: x01, y: y01 });
        let point11 = subSeries.toGlobal({ x: x11, y: y11 });
    
        line0.set("points", [point00, point01]);
        line1.set("points", [point10, point11]);
      }
    }
    
    // lines
    let line0 = container.children.push(
      am5.Line.new(root, {
        position: "absolute",
        stroke: root.interfaceColors.get("text"),
        strokeDasharray: [2, 2]
      })
    );
    let line1 = container.children.push(
      am5.Line.new(root, {
        position: "absolute",
        stroke: root.interfaceColors.get("text"),
        strokeDasharray: [2, 2]
      })
    );

    const seriesData = data.map((v) => {
      return {
        category: v.mappings,
        value: v.percentage,
        subData: v.subOccData.map((v1) => {
          return { category: v1.mappings, value: v1.percentage }
        })
      }
    });
    series.data.setAll(seriesData);

    series.slices.template.set("tooltip", am5.Tooltip.new(root, {
      labelText: "[fontSize: 12px]{category}: {value} %[/]"
    }));
    
    function selectSlice(slice: any) {
      selectedSlice = slice;
      let dataItem = slice.dataItem;
      let dataContext = dataItem.dataContext;
    
      if (dataContext) {
        let i = 0;
        subSeries.data.each(function(dataObject) {
          let dataObj = dataContext.subData[i];
          if(dataObj){
            if(!subSeries.dataItems[i].get("visible")){
                subSeries.dataItems[i].show();
            }
            subSeries.data.setIndex(i, dataObj);
          }
          else{
            subSeries.dataItems[i].hide();
          }
          
          i++;
        });
      }
    
      let middleAngle = slice.get("startAngle") + slice.get("arc") / 2;
      let firstAngle: any = series.dataItems[0].get("slice").get("startAngle");
    
      series.animate({
        key: "startAngle",
        to: firstAngle - middleAngle,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic)
      });
      series.animate({
        key: "endAngle",
        to: firstAngle - middleAngle + 360,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }
    
    container.appear(1000, 10);
    
    series.events.on("datavalidated", function() {
      const index: number = !selectedCategory ? 0 : seriesData.findIndex(f => f.category === selectedCategory);
      selectSlice(series.slices.getIndex(index));
    });

    return () => {
      root.dispose();
    };
  }, [data, selectedCategory, id, isLarge, isUpdation]);

  return (
    <div id={id} className="relative" style={styles}>
      <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
};

export const AmVariableRadiusPieChart: React.FC<{ 
  totalCoverages: number[]; isLegendVisible: boolean; styles?: any; id: string; isUpdation?: number;
}> = ({ id, totalCoverages, isLegendVisible, styles, isUpdation }) => {

  React.useLayoutEffect(() => {

    if (!document.getElementById(id as any)) {
      return;
    }

    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.horizontalLayout
    }));
    
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series: any = chart.series.push(am5percent.PieSeries.new(root, {
      alignLabels: false,
      calculateAggregates: true,
      valueField: "data",
      categoryField: "category",
      legendValueText: "$[/]{shortData}" //"{valuePercentTotal.formatNumber('0.p')}"
    }));

    activeOneSliceRemainingInActive(series);

    // Apply colors to pie slices
    const seriesColors: am5.ColorSet | undefined | any = series.get("colors");
    seriesColors.set("colors", [
      am5.Color.fromCSS("rgba(246, 212, 88, 0.8)"), am5.Color.fromCSS("rgba(78, 129, 188, 0.8)"),
      am5.Color.fromCSS("rgba(9, 145, 205, 0.8)"), am5.Color.fromCSS("rgba(239, 80, 23, 0.8)")
    ]);

    series.slices.template.setAll({
      strokeWidth: 3,
      stroke: am5.color(0xffffff),
    });
        
    const dataSet = [
      { category: "Building Value:", data: Math.round(totalCoverages[0]), shortData: Methods.numberFormatter(totalCoverages[0]), value: 6, percentage: Math.round((totalCoverages[0] * 100) / Methods.arrayValuesSum(totalCoverages)) }, 
      { category: "Other Value:", data: Math.round(totalCoverages[1]), shortData: Methods.numberFormatter(totalCoverages[1]), value: 8, percentage: Math.round((totalCoverages[1] * 100) / Methods.arrayValuesSum(totalCoverages)) },
      { category: "Contents Value:", data: Math.round(totalCoverages[2]), shortData: Methods.numberFormatter(totalCoverages[2]), value: 3, percentage: Math.round((totalCoverages[2] * 100) / Methods.arrayValuesSum(totalCoverages)) }, 
      { category: "BI Value:", data: Math.round(totalCoverages[3]), shortData: Methods.numberFormatter(totalCoverages[3]), value: 10, percentage: Math.round((totalCoverages[3] * 100) / Methods.arrayValuesSum(totalCoverages)) }
    ];
    series.data.setAll(dataSet);

    series.slices.template.set("tooltipText", "{category} $ {data} ({percentage}%)");
    series.slices.template.set("tooltip", am5.Tooltip.new(root, {
      labelText: "[fontSize: 12px]{category} $ {data} ({percentage}%)[/]"
    }));

    // Hide labels and ticks
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);
    
    // https://www.amcharts.com/docs/v5/concepts/settings/adapters/
    series.slices.template.adapters.add("radius", (radius: any, target: any) => {
      let dataItem = target.dataItem;
      let high: any = series.getPrivate("valueHigh");
      if (dataItem) {
        let value = dataItem.get("value", 0);
        return radius * value / high;
      }
      return Math.floor(Math.random() * (10 - 1 + 1) + 1);
    });

    if (isLegendVisible) {
      // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
      let legend = chart.children.unshift(am5.Legend.new(root, {
        centerY: am5.percent(48),
        y: am5.percent(48),
        centerX: am5.percent(8),
        x: am5.percent(8),
        layout: root.verticalLayout
      }));
      
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10
      });

      legend.data.setAll(series.dataItems);
      
      legend.events.on('click', () => {
        series.labels.template.set("forceHidden", true);
        series.ticks.template.set("forceHidden", true);
      });
    }
    
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };

  }, [id, isUpdation]);

  return (
    <div id={id} className="relative" style={styles}>
      <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
};

export const ForceDirectedLinksMap: React.FC<{ 
  arr: { confidence: string; count: number; }[]; styles?: any; id: string; totalLocations: number; isUpdation?: number;
  isLarge?: boolean;
}> = ({ id, arr, styles, totalLocations, isUpdation, isLarge }) => {

  React.useLayoutEffect(() => {

    if (!document.getElementById(id as any)) {
      return;
    }

    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let data = {
      value: 0,
      children: [] as any[]
    }

    arr.forEach((v) => {
      const percentage: number = ((v?.count * 100) / Methods.arrayValuesSum(arr.map((v1) => v1.count)));
      data.children.push({ name: v.confidence, value: v.count, percentage: Math.round(percentage) });
    });
        
    // Create wrapper container
    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout
      })
    );
    
    // Create series
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
    let series: any = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        singleBranchOnly: false,
        downDepth: 2,
        topDepth: 1,
        initialDepth: 1,
        maxRadius: isLarge ? 60 : 40,
        minRadius: isLarge ? 25 : 18,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        manyBodyStrength: -13,
        centerStrength: 0.8
      })
    );
    
    series.get("colors").set(
      "colors", 
      arr.map((d) => Constants.geocodeConfidences.find(f => f.type.toLowerCase() === d.confidence.toLowerCase())?.color)
    );

    series.get("colors").setAll({
      step: 1,
    });
    
    series.links.template.setAll({
      strokeWidth: 2,
    });
    
    series.nodes.template.setAll({
      tooltipText: "{percentage}% ({value} Locations)",
      cursorOverStyle: "pointer",
      label: {
        fontSize: 14 // Set font size here
      }
    });
    
    let selectedDataItem: any;
    
    // handle clicking on nodes and link/unlink them
    series.nodes.template.events.on("click", function(e: any) {
      // check if we have a selected data item
      if (selectedDataItem) {
        let targetDataItem = e.target.dataItem;
        // if yes, and it's the same, unselect
        if (e.target.dataItem == selectedDataItem) {
          selectedDataItem.get("outerCircle").setPrivate("visible", false);
          selectedDataItem = undefined;
        }
        // otherwise connect selected with a clicked point
        else {
          if (series.areLinked(selectedDataItem, targetDataItem)) {
            series.unlinkDataItems(selectedDataItem, targetDataItem);
          }
          else {
            series.linkDataItems(selectedDataItem, targetDataItem, 0.2);
          }
        }
      }
      // if no selected data item, select
      else {
        selectedDataItem = e.target.dataItem;
        selectedDataItem.get("outerCircle").setPrivate("visible", true)
      }
    });

    series.data.setAll([data]);
    series.set("selectedDataItem", series.dataItems[0]);
    
    // Make stuff animate on load
    series.appear(1000, 100);
    
    return () => {
      root.dispose();
    };

  }, [id, arr, totalLocations, isUpdation, isLarge]);

  return (
    <div id={id} className="relative" style={styles}>
      <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
};

export default PieChart;
