import * as React from 'react';

import { AmVariableRadiusPieChart } from "./PieChart";
import Methods from 'Libraries/CommonMethodsUI';

export default function TivCardContent({ totalCoverages, totalTiv, isUpdation }: { totalCoverages: number[]; totalTiv: number; isUpdation?: number; }): JSX.Element {
    return(
        <React.Fragment>
            <h4 className="text-center m-0 py-1 font-semibold text-black">
                Total Insurance Values: $ {Math.round(totalTiv)?.toLocaleString("en-US")}
            </h4>
            
            <div className="w-full" id="tiv-chart relative">
                <AmVariableRadiusPieChart isUpdation={isUpdation} id="radius-piechart-1" totalCoverages={totalCoverages} isLegendVisible styles={{ width: "100%", height: "550px" }} />
                <div className="text-base py-2 text-center border-t border-black px-2 w-52 absolute left-[72px] bottom-[30%] -translate-y-[27%]">
                    TIV: <span className='font-semibold'> $ {Methods.numberFormatter(totalTiv)} </span>
                </div>
            </div>
        </React.Fragment>
    );
}