import React, {useEffect, useState} from 'react';

import { RiFileExcel2Line } from 'react-icons/ri';
import { AiOutlineFilePdf, AiOutlineFileWord } from 'react-icons/ai';
import { FaFileCsv } from 'react-icons/fa';
import { Link } from "react-router-dom";
import ApiUrls from "Services/ApiUrls";
import Methods from "Libraries/CommonMethodsUI";
import {statusEnum} from "Libraries/Enums";
import ApiRequest from "Services/ApiRequest";
import {TokenService} from "Libraries/TokenService";

// contracts
interface Props {
    message?: string;
    arr?: string[] | any;
    isExposure?: boolean;
}

export const Attachments: React.FC<Props> = ({message, arr, isExposure}) => {
    const [exposureArr, setExposureArr] = useState<{data: string[], isError: boolean}>();
    const [loadData, setLoadData] = useState<boolean>(true);

    useEffect(()=> {
        if(isExposure && arr?.status === statusEnum[statusEnum.Exposures_Ready]){
            ApiRequest.exportUserGetRquest({control_number: arr?.control_number},(res: any)=>{
                const { isSuccess, data } = res;
                if(isSuccess){
                    setExposureArr({data: data?.response, isError: false}); setLoadData(false);
                } else {
                    setExposureArr({data: [], isError: true});setLoadData(false);
                }
            });
        }
    },[]);

    return (
        <div>
            <div className="font-bold text-2xl mb-2 text-center"> {isExposure ? 'Exposure Files' : 'Attachments'}  </div>

            <div className="flex flex-row flex-wrap items-center mt-4">
                {!isExposure && arr?.attachment?.map((item: any) => (
                    <Link className={'mx-4 my-2 text-lg outline-none border-none'} to={`${ApiUrls.baseUrl}/media/${item}?jwt=${TokenService.getAccessToken()}`} target="_blank" download>
                        {item.toLowerCase().includes('xls' || 'xlsx')
                            ? <div className={'flex items-center'}>
                                <RiFileExcel2Line className={'text-green-800'} style={{ fontSize: '25px' }}  />
                                <span className={'ml-2'}>{Methods.getfileName(item)}</span>
                              </div>
                            : item.toLowerCase().includes('pdf') ?
                                <div className={'flex items-center'}>
                                    <AiOutlineFilePdf  className={'text-red-600'} style={{ fontSize: '25px' }} />
                                    <span className={'ml-2'}>{Methods.getfileName(item)}</span>
                                </div>
                            : item.toLowerCase().includes('doc' || 'docx') ?
                                <div className={'flex items-center'}>
                                    <AiOutlineFileWord  className={'text-blue-600 '} style={{ fontSize: '25px' }} />
                                    <span className={'ml-2'}>{Methods.getfileName(item)}</span>
                                </div>
                            : <><AiOutlineFileWord />{Methods.getfileName(item)}</>
                        }
                    </Link>
                ))}
            </div>

            {(isExposure && loadData) ? <div className='m-2'>{'Loading...'}</div>
                : (isExposure && !loadData) &&
                <div className="flex flex-row flex-wrap items-center mt-4">
                    {exposureArr?.data.length ? exposureArr?.data?.map((item: any) => (
                        <Link className={'mx-4 my-2 text-md outline-none border-none'} to={`https://sov-wizard-stage.s3.amazonaws.com/${item}`} target="_blank" download>
                            <div className={'flex flex-row items-center'}>
                                <div className={'mr-2 text-green-600'} style={{ fontSize: '25px' }}><FaFileCsv /></div>{item}
                            </div>
                        </Link>
                    )) : <div className='text-red-500 m-2'>{'No user template available!'}</div>}
                </div>
            }

        </div>

    );
};
