import * as React from 'react';

import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import MasterLayout from 'Hoc/MasterLayout';
import { useLocation, useParams } from 'react-router-dom';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import { Paths } from 'Libraries/Route';
import ApiRequest from 'Services/ApiRequest';
import { IMapMarker, IMappingAssumptions, IReportData } from 'Libraries/Interfaces';
import { SpinnerLoader } from 'Components/Loader/Loader';
import GoogleMap from 'Components/GoogleMap/GoogleMap';
import Methods from 'Libraries/CommonMethodsUI';
import { Tabs } from 'Components/Tabs/Tabs';
import withAuthentication from 'Hoc/WithAuthentication';
import HeatMap, { ChoroplethHeatMap} from 'Components/Charts/HeatMap';
import { BarChartSection, GeoCodeConfidenceSection, GuageChartSection, MappingDoughnutSection, TIVChartSection } from './ReportElements';
import { useFetchCurrentSubmission } from 'Hooks/UseFetch';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    Tooltip
);

function ReportAnalytics({ user, navigate }: any): JSX.Element {

    const { controlnumber, version } = useParams<{ controlnumber: string; version: string; }>();
    const { search } = useLocation();

    const [mapType, setMapType] = React.useState<number>(2);

    const [report, setReport] = React.useState<IReportData>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const { submission } = useFetchCurrentSubmission(controlnumber as string);

    const [mappingsAssumptions, setMappingsAssumptions] = React.useState<IMappingAssumptions>({
        construction_data: [], occupancy_data: []
    });

    const getReportData = (): void => {
        if (version && controlnumber) {
            setLoading(true);
            const params: string = `?version=${version}&control_number=${controlnumber}`;
            ApiRequest.getDTReportJson(params, (res) => {
                const result: IReportData = res?.data?.data_transparency_report_json;
                setReport(result ?? undefined);

                if (result) {
                    ApiRequest.getConstOccPieChartData({
                        raw_const_list: result?.raw_const_list?.map(v => v?.toString()) ?? [],
                        raw_occ_list: result?.raw_occ_list?.map(v => v?.toString()) ?? [],
                        const_mapping_data: result.constructionMappings.map((v) => {
                            return { construction: v.construction, mapping: v.mapping, construction_code_id: v.construction_code_id };
                        }),
                        occ_mapping_data: result.occupancyMappings.map((v) => {
                            return { occupancy: v.occupancy, mapping: v.mapping, occupancy_code_id: v.occupancy_code_id };
                        })
                    }, (res) => {
                        if (res.isSuccess) {
                            setMappingsAssumptions(res.data);
                        }
                    }).catch(() => {
                        setMappingsAssumptions({ construction_data: [], occupancy_data: [] });
                    });
                }
                
                setLoading(false);
            });
        }
    };

    React.useEffect(() => {
        getReportData();   
    }, []);

    if (!report || loading || Object.keys(report).length === 0) {
        return (
            <MasterLayout mainTitle={`${submission?.name_insured}`} navigate={() => navigate(`${Paths.dashboard}/${controlnumber}${search}`)} className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string}>
                <DocumentTitle title={`${submission?.name_insured}`}>
                    <div className="flex flex-col py-5 justify-center items-center">
                        { loading ? <SpinnerLoader adjustment={true} enhance="text-black text-base" /> : (
                            <span className="text-base text-black">Not found.</span>
                        )}
                    </div>
                </DocumentTitle>
            </MasterLayout>
        )
    }

    return (
        <MasterLayout mainTitle={`${submission?.name_insured ?? ''}`} isReportTab={true} navigate={() => navigate(`${Paths.dashboard}/${controlnumber}${search}`)} className='px-2 relative' mainClassName="overflow-hidden" userEmail={user?.email as string}>
            <DocumentTitle title={`${submission?.name_insured ?? ''}`}>

                <div className="grid grid-cols-9 gap-2 h-[90vh] p-2">

                    <div className="col-span-4 relative bg-inherit border-2 border-gray-200 rounded-md p-2">
                        <Tabs 
                            activeClassName="mb-2 mr-3 button-39" inActiveClassName="mb-2 mr-3 button-39" onChange={setMapType} active={mapType} 
                            tabs={[ { id: 1, name: 'Google Map' }, { id: 2, name: 'Heat Map' }, { id: 3, name: 'World Map' } ]} 
                        />

                        { (mapType === 1 && report?.markers?.length > 0) ? (
                            <GoogleMap mapHeight='h-[82vh]' markers={report?.markers} />
                        ) : (mapType === 2 && report?.markers?.length > 0) ? (
                            <HeatMap isUpdated={mapType} markers={report?.markers?.map((v) => { return { ...v, count: 100 } }) as IMapMarker[]} />
                        ) : (mapType === 3 && report?.markers?.length > 0) ? (
                            <ChoroplethHeatMap 
                                countyMatrix={Methods.removeDublicatesInArray(report?.utilityMatrices?.countyMatrix ?? [])} styles={{ width: '100%', height: '82vh' }} 
                                id="clustered-map-4" markers={report?.markers?.map((v) => { return { ...v, count: 100 } }) as IMapMarker[]} 
                            />
                        ) : null }
                    </div>

                    <div className="col-span-5 relative bg-inherit h-[90vh]">

                        <div className='grid grid-cols-4 gap-2 h-[16vh]'>
                            <TIVChartSection report={report} />
                            <GuageChartSection report={report} />
                        </div>

                        <div className='relative col-span-3 border-2 border-gray-200 rounded-md pt-2 pl-2 my-2 h-[46vh]'>
                            <BarChartSection report={{ ...report, utilityMatrices: {
                                ...report?.utilityMatrices, 
                                constructionMatrix: mappingsAssumptions.construction_data.map((v) => {
                                    const finder: string[] = [...new Set(report?.constructionMappings.filter((f) => f.mapping.trim() === v.mappings.trim()).map(f => f.construction.trim()))];
                                    const matcher = report?.utilityMatrices?.constructionMatrix?.filter((f) => finder.includes(f.label?.toString()?.trim()));
                                
                                    return {
                                        label: v.mappings, ...Methods.sumOfArrayObjectsForMappings(matcher)
                                    }
                                }),
                                occupancyMatrix: mappingsAssumptions.occupancy_data.map((v) => {
                                    const finder: string[] = [...new Set(report?.occupancyMappings.filter((f) => f.mapping.trim() === v.mappings.trim()).map(f => f.occupancy.trim()))];
                                    const matcher = report?.utilityMatrices?.occupancyMatrix?.filter((f) => finder.includes(f.label?.trim()));
                                
                                    return {
                                        label: v.mappings, ...Methods.sumOfArrayObjectsForMappings(matcher)
                                    }
                                })
                            } }} />
                        </div>

                        <div className='grid grid-cols-3 gap-2 h-[26vh]'>
                            <div className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                <GeoCodeConfidenceSection report={report} />
                            </div>
                            {[
                                { id: 1, title: 'Construction', value: mappingsAssumptions.construction_data },
                                { id: 2, title: 'Occupancy', value: mappingsAssumptions.occupancy_data }
                            ].map((obj) => (
                                <div key={obj.id} className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                    <MappingDoughnutSection 
                                        activeTab={obj.id} item={{ title: obj.title, value: obj.value?.length ?? 0 }} chartId={`mappings-modal-${obj.id}`}
                                        data={obj.value as any} tableData={obj.id === 1 ? report?.constructionMappings : report?.occupancyMappings}
                                    />
                                </div>
                            ))}
                        </div>

                    </div>

                </div>

            </DocumentTitle>
        </MasterLayout>
    );
}

export default withAuthentication(ReportAnalytics, Paths.login, 1);