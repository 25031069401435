import React, {useState} from 'react';

// router
import { useNavigate, Link } from 'react-router-dom';

// Components
import {Button} from "Components/Button/Button";
import {Input} from "Components/Input/Input";
import {SpinnerLoader} from "Components/Loader/Loader";
import {Paths} from "Libraries/Route";
import { toast } from 'react-toastify';

// Icons
import ApiRequest from "Services/ApiRequest";
import AuthWrapper from 'Hoc/AuthWrapper';
import Methods from 'Libraries/CommonMethodsUI';

// Interface
interface Props {}

const ForgotPasswordScreen:React.FC<Props> = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<{ emailError: string; }>({ emailError: '' });
    const [isResetLoading, setIsResetLoading] = useState<boolean>(false);
    const [resetRequest, setResetRequest] = useState<boolean>(false);

    async function forgotPasswordUser(): Promise<void> {        
        if(!email) setError({ ...error, emailError: 'Email Address not exist' });
        else{
            setIsResetLoading(true);
            setError({emailError: ''});
            try {
                await ApiRequest.resetPasswordTryRequest({email});
                toast.success('Email sent successfully.');
                setIsResetLoading(false);
                setResetRequest(true);
            }
            catch (e: any) {
                setResetRequest(false);
                setIsResetLoading(false);
            }
        }
    };

    if (resetRequest) {
        return (
            <AuthWrapper email={undefined} backForward={true}>
                <React.Fragment>
                    <h6 className="font-semibold text-white text-center mb-1 text-xl">Check Your Email</h6>
                    <span className='text-center text-white my-0 mx-auto text-xs block w-80'>
                        We have sent an email with OTP reset information to {Methods.obscureEmail(email)}.
                    </span>
                    <br /><br /><br />
                    <Button
                        onClick={() => navigate(`${Paths.forgotPasswordConfirm}?email=${email}`)} id={'login-submit-btn'} disabled={false}
                        className="flex w-full rounded-lg border border-transparent h-10 flex-col items-center
                        text-sm font-medium text-white focus:outline-none m-0 bg-primary-skyblue justify-center hover:bg-opacity-60 transition-all duration-200"
                        title="Go to Reset Password"                    
                    />
                </React.Fragment>
            </AuthWrapper>
        );
    }

    return (
        <AuthWrapper email={undefined} backForward={true}>         
            <React.Fragment>
                <h6 className="font-semibold text-white text-center mb-1 text-xl">Forgot Password</h6>
                <span className='text-center text-white my-0 mx-auto text-xs block w-80'>
                    Enter the email you used to create your account so we can send you instructions on how to reset your password.
                </span>
                <br /><br />

                <div className="mb-3">
                    <Input
                        id="login-password-email" name="email" type="email"
                        value={email} autoComplete={'off'} placeholder="Enter Email"
                        onChange={(e: any)=> setEmail(e.target.value )}                        
                        className={`block w-full text-white appearance-none rounded-lg border border-white px-3 py-2 ${error.emailError ? 'border-red-500' : 'border-white'} 
                        placeholder-white bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                    />
                    <div className="my-1 text-xs text-red-500">
                        {error.emailError && error.emailError}
                    </div>
                </div>

                <Button
                    onClick={() => forgotPasswordUser()} id={'login-submit-btn'} disabled={isResetLoading}
                    className="flex w-full rounded-lg border border-transparent h-10 flex-col items-center
                    text-sm font-medium text-white focus:outline-none m-0 bg-primary-skyblue justify-center hover:bg-opacity-60 transition-all duration-200"
                    title={isResetLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Enter'}                    
                />
                <br />

                <Link className='text-white hover:opacity-60 transition-all duration-200 border border-white flex flex-col justify-center items-center h-10 rounded-lg' to={Paths.login}>Back to Login</Link>
            </React.Fragment>
        </AuthWrapper>        
    )
};

export default ForgotPasswordScreen;
