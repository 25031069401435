import * as React from 'react';

import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import MasterLayout from 'Hoc/MasterLayout';
import { useLocation, useParams } from 'react-router-dom';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import withAuthentication from 'Hoc/WithAuthentication';
import { Paths } from 'Libraries/Route';
import CustomTable2 from 'Components/CustomTable/CustomTable';
import ApiRequest from 'Services/ApiRequest';
import { IReportData } from 'Libraries/Interfaces';
import { DataCompletenessForReport, DataMappedForReport, DataQualityForReport } from './DataReports';
import Constants from 'Libraries/Constants';
import { CustomBarChart, useFetchBarChartDataSetsForReport } from 'Components/Charts/BarChart';
import TivCardContent from 'Components/Charts/TIVCard';
import { SpinnerLoader } from 'Components/Loader/Loader';
import GoogleMap from 'Components/GoogleMap/GoogleMap';
import { Button } from 'Components/Button/Button';
import { Images } from 'Libraries/Images';
import { useReactToPrint } from 'react-to-print';
import { FirstPageHeading, MainTitle, ReportFooter, ReportHeaderMin } from './ReportElements';
import Methods from 'Libraries/CommonMethodsUI';
import { CustomPieChart } from 'Components/Charts/PieChart';
import html2canvas from 'html2canvas';
import { CircularProgressbar } from 'react-circular-progressbar';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    Tooltip
);

function ReportVersion({ user, navigate }: any): JSX.Element {

    const { controlnumber, version } = useParams<{ controlnumber: string; version: string; }>();
    const { state } = useLocation();

    const refReport = React.useRef<HTMLDivElement | any>();

    const [report, setReport] = React.useState<IReportData>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [percentageComplete, setPercentageComplete] = React.useState<number>(0);
    const [printLoading, setPrintLoading] = React.useState<boolean>(false);
    const [showGoogleMap, setShowGoogleMap] = React.useState<boolean>(false);
    const [removeLineBreaks, setRemoveLineBreaks] = React.useState<boolean>(false);

    const getReportData = (): void => {
        if (version && controlnumber) {
            setLoading(true);
            const params: string = `?version=${version}&control_number=${controlnumber}`;
            ApiRequest.getDTReportJson(params, (res) => {
                const result: IReportData = res?.data?.data_transparency_report_json;
                setReport(result ?? undefined);
                
                if (result && result?.markers?.length > 0) {
                    setTimeout(() => {
                        setShowGoogleMap(true);
                    }, 1000);
                }
                
                setLoading(false);
            });
        }
    };

    React.useEffect(() => {
        getReportData();
    }, []);

    const graphs = useFetchBarChartDataSetsForReport(report?.selectedGeographies ?? [] as string[], report?.utilityMatrices ?? {});

    const pageStyle = `
        @page { 
            size: A4; margin: 2mm; 
        } 
        @media print {
            body { -webkit-print-color-adjust: exact; }
            .watermark {
                position: fixed;
                top: 50vh;
                z-index: 9;
                width: 50vw;
                page-break-after: always;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.1;
            }
        }
    `;

    async function returnHTMLAsImageById(writeElementId: string, isDoughnut: boolean, isMap: boolean, isPie?: boolean): Promise<void> {
        const element: HTMLElement | any = document.getElementById(writeElementId);

        const canvas = await html2canvas(element, { allowTaint: true, useCORS: true, logging: true, scale: 5 });
        canvas.getContext("2d", { willReadFrequently: true });
        const url: string = canvas.toDataURL('image/png', 5); // 5 is quality scale

        if (isMap) {
            element.innerHTML = '<img alt="" src="' + url + '" height="480px" style="margin auto; display: block; width: 96%;" />';
        } else if(isDoughnut) {
            element.outerHTML = '<img alt="" src="' + url + '" style="height: 140px; margin-left: auto; margin-right: auto; display: block; width: 99%;" />';            
        } else if(isPie) {
            element.outerHTML = '<img alt="" src="' + url + '" style="margin-left: auto; margin-right: auto; display: block; width: 55%;" />';
        } else {
            element.outerHTML = '<img alt="" src="' + url + '" style="margin-left: auto; margin-right: auto; display: block; width: 99%;" />';
        }
    };

    const generatePDF = useReactToPrint({
        content: () => {
            const tableStat = refReport.current.cloneNode(true);
            const PrintElem = document.createElement('div');
            const header = `<img src="${Images.logo}" alt="company-logo" class="watermark"/>`;
            PrintElem.innerHTML = header;
            PrintElem.appendChild(tableStat);
            return PrintElem;
        },
        pageStyle: pageStyle,
        documentTitle: `${state?.nameInsured}-ExposureReport-${Methods.reportExportTime()}`,
        removeAfterPrint: true,
        onAfterPrint: () => {
            setPercentageComplete(0);
            setPrintLoading(false);
            setRemoveLineBreaks(false);
            (document.getElementById("report-footer") as HTMLElement).style.position = "relative";
            navigate(`${Paths.dashboard}/${controlnumber}`, { state });
        },
        fonts: [{ family: "Arial", source: "../../Fonts/Arial.ttf" }],
        onPrintError: () => {
            setPercentageComplete(0);
            setPrintLoading(false);
            setRemoveLineBreaks(false);
            (document.getElementById("report-footer") as HTMLElement).style.position = "relative";
        }
    });

    async function exportPdfReport(): Promise<void> {
        setPrintLoading(true);
        setRemoveLineBreaks(true);
        setPercentageComplete(1);
        
        if (showGoogleMap) {
            await returnHTMLAsImageById("report-gmap", false, true);
        }
        setPercentageComplete(8);

        if ((report?.selectedGeographies as string[])?.includes("Geocode Confidence")) {
            await returnHTMLAsImageById("geocode-piechart", false, false, true);
        }
        setPercentageComplete(13);

        await returnHTMLAsImageById("tiv-chart", false, false, true);
        setPercentageComplete(20);

        if (report?.dataMapped) {
            await returnHTMLAsImageById("doughnut-charts-dm", true, false);
        }
        setPercentageComplete(30);

        if (report?.dataCompleteness) {
            await returnHTMLAsImageById("doughnut-charts-dc", true, false);
        }
        setPercentageComplete(40);

        if (report?.dataQuality) {
            await returnHTMLAsImageById("doughnut-charts-dq", true, false);
        }
        setPercentageComplete(50);

        for (let i = 0; i < graphs.length; i++) {
            await returnHTMLAsImageById(`bar-charts-${i}`, false, false);
            setPercentageComplete(prev => Math.round((prev + (50/graphs.length)) * 100) / 100);
        }

        (document.getElementById("report-footer") as HTMLElement).style.position = "fixed";
        (document.getElementById("report-footer") as HTMLElement).style.bottom = "0px";
        (document.getElementById("report-footer") as HTMLElement).style.left = "2px";
        (document.getElementById("report-footer") as HTMLElement).style.right = "2px";

        if (graphs.length === 0) {
            setPercentageComplete(100);
        }

        generatePDF();
    }

    if (!report || loading || Object.keys(report).length === 0) {
        return (
            <MasterLayout mainTitle={`Report (${state?.nameInsured})`} navigate={() => navigate(`${Paths.dashboard}/${controlnumber}`, { state })} className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string}>
                <DocumentTitle title={`Report (${state?.nameInsured})`}>
                    <div className="flex flex-col py-5 justify-center items-center">
                        { loading ? <SpinnerLoader adjustment={true} enhance="text-black text-base" /> : (
                            <span className="text-base text-black">Not found.</span>
                        )}
                    </div>
                </DocumentTitle>
            </MasterLayout>
        )
    }

    return (
        <MasterLayout mainTitle={`Report (${state?.nameInsured})`} navigate={() => navigate(`${Paths.dashboard}/${controlnumber}`, { state })} className='px-4 sm:px-6 lg:px-8 relative' userEmail={user?.email as string}>
            <DocumentTitle title={`Report (${state?.nameInsured})`}>
                
                { (printLoading && percentageComplete > 0) ? (
                    <div className='float-right w-16 h-16 text-base mb-3'>
                        <CircularProgressbar value={parseFloat(percentageComplete.toFixed(1))} text={`${percentageComplete}%`} />
                    </div>
                ) : (
                    <Button title="Export" loading={printLoading} onClick={() => exportPdfReport()} 
                        className="h-8 w-24 rounded-md bg-primary-green hover:bg-opacity-60 transition-all duration-200 text-base text-white mb-3 float-right" 
                    />
                )}
                
                <div ref={refReport} id="html-windows-pdf" className="arial-font relative" style={{ pageBreakInside: 'avoid', display: 'flex', flexDirection: 'column', width: '100%', background: "#fff" }}>

                    <table>
                        <thead>
                            <tr>
                                <td>    
                                    <ReportHeaderMin />
                                    <br />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='block'>
                                    <FirstPageHeading insuredName={state?.nameInsured} />
                                </td>
                            </tr>
                            <tr className="break-before-page clear-both block">
                                <td className='block'>
                                    <MainTitle fontSize={24} title="Data Transparency Report" textDecoration="underline" /><br />

                                    { showGoogleMap && (
                                        <section id="report-gmap" className="flex flex-col items-center justify-center">
                                            <GoogleMap mapHeight='h-[31vh]' markers={report?.markers} />
                                            <br />
                                        </section>
                                    )}

                                    <h4 className="m-0 text-xl text-center block w-100 font-semibold">
                                        Raw SOV Data Summary
                                    </h4>
                                    <br />

                                    <CustomTable2 headers={[{ value: "ColumnName", textAlign: "left", width: "50%" }, { value: "Data", textAlign: "left", width: "50%" }]}>
                                        { [...report?.summaryTable].map((s: { columnName: string; value: string; }) => (
                                            <tr key={s?.columnName} className="border-collapse outline-0 align-middle table-row text-inherit">
                                                { [s?.columnName, s?.value].map((h: string, i: number) => (
                                                    <td className='leading-6 text-sm table-cell text-black border-b border-[#f1f1f1] px-4 w-1/2 py-1.5 text-left' key={i}> {h} </td>
                                                )) }
                                            </tr>
                                        ))}
                                    </CustomTable2>
                                    { !removeLineBreaks && (<><br /><br /></>)}
                                    <br /><br />
                                </td>
                            </tr>
                            
                            { (report?.selectedGeographies as string[])?.includes("Geocode Confidence") ? (
                                <tr className="break-before-page clear-both block">
                                    <td className='block'>
                                        <h4 className="m-0 text-xl text-center block w-100 font-semibold">
                                            Geocode Confidence Summary
                                        </h4>
                                        <br />
                                        
                                        <div className="w-1/2 mx-auto" id="geocode-piechart">
                                            <CustomPieChart 
                                                labels={report?.geoconfidenceSummary.map((v) => v.confidence)} data={report?.geoconfidenceSummary.map((v) => v.count)} 
                                                colors={report?.geoconfidenceSummary.map((v) => v.confidence).map((lab) => Constants.geocodeConfidences.find((f) => f.type.toLowerCase() === lab.toLowerCase())?.color as string)}
                                            />
                                        </div>
                                        <br />

                                        <CustomTable2 headers={[
                                            { value: "Geocode Confidence", textAlign: "left", width: "33.33%" },
                                            { value: "Count", textAlign: "left", width: "33.33%" },
                                            { value: "Mapped Percentage", textAlign: "left", width: "33.33%" }
                                        ]}>
                                            {report?.geoconfidenceSummary.map((s, i: number) => {
                                                const percentage: number = ((s?.count * 100) / Methods.arrayValuesSum(report?.geoconfidenceSummary.map((v) => v.count)));

                                                return (
                                                    <tr key={i} className="border-collapse outline-0 align-middle table-row text-inherit">
                                                        { [
                                                            s?.confidence, s?.count,
                                                            percentage % 2 === 0 ? `${percentage}%` : `${percentage.toFixed(1)}%`
                                                        ].map((h: string | any, i: number) => (
                                                            <td className='leading-6 text-sm table-cell text-black border-b border-[#f1f1f1] px-4 w-1/3 py-1.5 text-left' key={i}> {h} </td>
                                                        )) }
                                                    </tr>
                                                );
                                            })}
                                        </CustomTable2>
                                        { !removeLineBreaks && (<><br /><br /></>)}
                                        <br /><br />
                                    </td>
                                </tr>
                            ) : null }
                            
                            <tr className="break-before-page clear-both block">
                                <td className='block'>
                                    <h4 className="m-0 text-xl text-center block w-100 font-semibold">
                                        Construction Mapping Assumptions
                                    </h4>
                                    <br />

                                    <CustomTable2 headers={[
                                        { value: "Reported Construction", textAlign: "left", width: "33.33%" },
                                        { value: "Mapped to Scheme", textAlign: "left", width: "33.33%" },
                                        { value: "Mapped to Code", textAlign: "left", width: "33.33%" },
                                    ]}>
                                        {[...report?.constructionMappings].map((c) => (
                                            <tr key={c?.construction} className="border-collapse outline-0 align-middle table-row text-inherit">
                                                { [c?.construction, c?.scheme, c?.mapping ? `${c?.mapping} (${c?.construction_code_id})` : ""].map((h: string, i: number) => (
                                                    <td className='leading-6 text-sm table-cell text-black border-b border-[#f1f1f1] px-4 w-1/3 py-1.5 text-left' key={i}> {h} </td>
                                                )) }
                                            </tr>
                                        ))}
                                    </CustomTable2>
                                    { !removeLineBreaks && (<><br /><br /></>)}
                                    <br /><br />
                                </td>
                            </tr>
                            
                            <tr className="break-before-page clear-both block">
                                <td className='block'>
                                    <h4 className="m-0 text-xl text-center block w-100 font-semibold">
                                        Occupancy Mapping Assumptions
                                    </h4>
                                    <br />

                                    <CustomTable2 headers={[
                                        { value: "Reported Occupancy", textAlign: "left", width: "33.33%" },
                                        { value: "Mapped to Scheme", textAlign: "left", width: "33.33%" },
                                        { value: "Mapped to Code", textAlign: "left", width: "33.33%" }
                                    ]}>
                                        {[...report?.occupancyMappings].map((c) => (
                                            <tr key={c?.occupancy} className="border-collapse outline-0 align-middle table-row text-inherit">
                                                { [c?.occupancy, c?.scheme, c?.mapping ? `${c?.mapping} (${c?.occupancy_code_id})` : ""].map((h: string, i: number) => (
                                                    <td className='leading-6 text-sm table-cell text-black border-b border-[#f1f1f1] w-1/3 px-4 py-1.5 text-left' key={i}> {h} </td>
                                                )) }
                                            </tr>
                                        ))}
                                    </CustomTable2>
                                    { !removeLineBreaks && (<><br /><br /></>)}
                                </td>
                            </tr>

                            <tr className="break-before-page clear-both block">
                                <td className='block'>
                                    <section>
                                        <MainTitle fontSize={24} title="Data Scores" textDecoration="underline" />
                                        <br />
                                        <DataMappedForReport dataMapped={report?.dataMapped} title="Data Mapped Report" />
                                    </section>
                                    { !removeLineBreaks && (<br />)}
                                </td>
                            </tr>
                            
                            <tr className="break-before-page clear-both block">
                                <td className='block'>
                                    <section>
                                        <DataCompletenessForReport dataCompleteness={report?.dataCompleteness} title="Data Completeness Report" />
                                    </section>
                                    { !removeLineBreaks && (<br />)}
                                </td>
                            </tr>
                            
                            <tr className="break-before-page clear-both block">
                                <td className='block'>
                                    <section>
                                        <DataQualityForReport title="Data Quality Report" dataQuality={report?.dataQuality} />
                                    </section>
                                    { !removeLineBreaks && (<><br /><br /></>)}
                                </td>
                            </tr>
                            
                            <tr className="break-before-page clear-both block">
                                <td className='block'>
                                    <MainTitle fontSize={24} title="TIV by Coverage" textDecoration="underline" />
                                    <section>
                                        <TivCardContent totalCoverages={report.totalCoverages} totalTiv={Math.round(report?.totalTiv)} /><br />
                                        <br />
                                    </section>
                                </td>
                            </tr>

                            { graphs.map((charts, i: number) => (
                                <tr className="break-before-page clear-both block" key={i}>
                                    <td className='block'>
                                        <div id={`bar-charts-${i}`}>
                                            { charts.map((item, ind: number) => (
                                                <div key={ind}>
                                                    { Methods.removeDublicatesInArray(charts.map((c) => c.title))[ind] && (
                                                        <MainTitle fontSize={20} title={item.title} textDecoration="underline" />
                                                    )}
                                                    <CustomBarChart height={245} isTitle={true} labels={item.labels} datasets={item.dataSet} mode={item.mode} />
                                                    <br />
                                                </div>
                                            )) }
                                        </div>
                                    </td>
                                </tr>
                            )) }

                        </tbody>
                        <tfoot>
                            <tr className="h-32">
                                <td className='block'>
                                    <div id="report-footer" className="w-full h-[100px] mt-6">
                                        <ReportFooter />
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                </div>
            </DocumentTitle>
        </MasterLayout>
    );
}

export function ReportTivByCoverage(): JSX.Element {
    const { state } = useLocation();

    const ReportContent = ({ user, navigate }: any) => (
        <MasterLayout mainTitle={`Report (${state?.nameInsured})`} navigate={() => navigate("..", { state })} className='px-4 sm:px-6 lg:px-8 relative' userEmail={user?.email as string}>
            <DocumentTitle title={`Report (${state?.nameInsured})`}>
                <table className="w-full">
                    <tbody className='p-3'>
                        <tr className="break-before-page clear-both block">
                            <td className='block'>
                                <MainTitle fontSize={24} title="TIV by Coverage" textDecoration="underline" />
                                <section>
                                    <TivCardContent totalCoverages={state?.totalCoverages} totalTiv={Math.round(state?.totalTiv)} /><br />
                                    <br />
                                </section>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DocumentTitle>
        </MasterLayout>
    );

    const AuthenticatedReportContent = withAuthentication(ReportContent, Paths.login, 1);
    return <AuthenticatedReportContent />;
}

export function ReportDataMapped(): JSX.Element {
    const { state } = useLocation();

    const ReportContent = ({ user, navigate }: any) => (
        <MasterLayout mainTitle={`Report (${state?.nameInsured})`} navigate={() => navigate("..", { state })} className='px-4 sm:px-6 lg:px-8 relative' userEmail={user?.email as string}>
            <DocumentTitle title={`Report (${state?.nameInsured})`}>
                <table className="w-full">
                    <tbody className='p-3'>
                        <tr className="break-before-page clear-both block">
                            <td className='block'>
                                <section>
                                    <MainTitle fontSize={24} title="Data Scores" textDecoration="underline" />
                                    <br />
                                    <DataMappedForReport dataMapped={state?.dataMapped} title="Data Mapped Report" />
                                </section>
                                <br />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DocumentTitle>
        </MasterLayout>
    );

    const AuthenticatedReportContent = withAuthentication(ReportContent, Paths.login, 1);
    return <AuthenticatedReportContent />;
}

export function ReportDataCompleteness(): JSX.Element {
    const { state } = useLocation();

    const ReportContent = ({ user, navigate }: any) => (
        <MasterLayout mainTitle={`Report (${state?.nameInsured})`} navigate={() => navigate("..", { state })} className='px-4 sm:px-6 lg:px-8 relative' userEmail={user?.email as string}>
            <DocumentTitle title={`Report (${state?.nameInsured})`}>
                <table className="w-full">
                    <tbody className='p-3'>
                        <tr className="break-before-page clear-both block">
                            <td className='block'>
                                <section>
                                    <MainTitle fontSize={24} title="Data Scores" textDecoration="underline" />
                                    <br />
                                    <DataCompletenessForReport dataCompleteness={state?.dataCompleteness} title="Data Completeness Report" />
                                </section>
                                <br />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DocumentTitle>
        </MasterLayout>
    );

    const AuthenticatedReportContent = withAuthentication(ReportContent, Paths.login, 1);
    return <AuthenticatedReportContent />;
}

export function ReportDataQuality(): JSX.Element {
    const { state } = useLocation();

    const ReportContent = ({ user, navigate }: any) => (
        <MasterLayout mainTitle={`Report (${state?.nameInsured})`} navigate={() => navigate("..", { state })} className='px-4 sm:px-6 lg:px-8 relative' userEmail={user?.email as string}>
            <DocumentTitle title={`Report (${state?.nameInsured})`}>
                <table className="w-full">
                    <tbody className='p-3'>
                        <tr className="break-before-page clear-both block">
                            <td className='block'>
                                <section>
                                    <MainTitle fontSize={24} title="Data Scores" textDecoration="underline" />
                                    <br />
                                    <DataQualityForReport dataQuality={state?.dataQuality} title="Data Quality Report" />
                                </section>
                                <br />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DocumentTitle>
        </MasterLayout>
    );

    const AuthenticatedReportContent = withAuthentication(ReportContent, Paths.login, 1);
    return <AuthenticatedReportContent />;
}

export default withAuthentication(ReportVersion, Paths.login, 1);