import Methods from 'Libraries/CommonMethodsUI';
import React from 'react';
interface Props {
    className: string;
    id: string;
    type: any;
    autoComplete?: string;
    onChange: (e: any) => void;
    keyPress?: (e: any) => void;
    name?: string;
    value?: string | number;
    placeholder?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    required?: boolean;
    isHandleOnBlur?: boolean;
}

export const Input:React.FC<Props> = (props) => {

    const { placeholder, className, id, type, onChange, value, autoComplete, inputRef, keyPress, name, required, isHandleOnBlur, ...rest } = props;

    const handleOnBlur = (val: string) => {
        // if user enters number with text, convert it to number else do nothing. example 1M should be 1,000,000
        if (val && /[a-zA-Z]/g.test(val)) {
            let num = val?.split(/[a-zA-Z]/g);
            let wrd = val?.split(/[0-9]/g);
            const converted = Methods.wordsToNumber(wrd[wrd.length - 1], num[0], val);
            onChange({ target: { value: converted.toLocaleString('en-US') } });
        }
    };

    return (
        <input
            name={name}
            id={id}
            type={type}
            value={value}
            className={className}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={(e) => onChange(e)}
            onKeyPress={(e) => keyPress ? keyPress(e) : null}
            ref={inputRef}
            required={required ? required : false}
            onBlur={() => isHandleOnBlur && handleOnBlur(value as any)}
            {...rest} />
    );
};
