class Urls{
    public readonly baseUrl = process.env.REACT_APP_API_KEY + '/api_v1';
    public readonly loginUrl = this.baseUrl + '/user/login_with_password/';
    public readonly signUpUrl = this.baseUrl + '/user/';
    public readonly SignOutUrl = this.baseUrl + '';
    public readonly getUserDetails = this.baseUrl + '/user/get_user_details/';
    public readonly tokenRefreshUrl = this.baseUrl + '/user/token/refresh/';
    public readonly getSubmissionUrl = this.baseUrl + '/under_writer/submission/';
    public readonly getSubmissionVersionsUrl = this.baseUrl + '/under_writer/versions/';
    public readonly changePasswordUrl = this.baseUrl + '/user/change-password';
    public readonly resetPasswordRequestUrl = this.baseUrl + '/user/api/reset-password/';
    public readonly resetPasswordConfirmUrl = this.baseUrl + '/user/api/reset-password/confirm/';
    public readonly createLogUrl = this.baseUrl + '/under_writer/create-logs/';
    public readonly submissionAssign = this.baseUrl + '/under_writer/submission-assign/';
    public readonly submissionUpdate = this.baseUrl + '/under_writer/submission-update/';
    public readonly submissionGetUpdate = this.baseUrl + '/under_writer/submission-update-logs/';
    public readonly exportTemplate = this.baseUrl + '/sov_wizard/submission/export-user-template-download/';
    public readonly versionComments = this.baseUrl + '/under_writer/comment/';
    public readonly fetchHistory = this.baseUrl + '/under_writer/history/';
    public readonly exposureFiles = this.baseUrl + '/under_writer/attachment/';
    public readonly exposureTemplates = this.baseUrl + '/sov_wizard/submission/export-user-template-download/';
    public readonly dashboardCounts = this.baseUrl + '/under_writer/dashboard/';
    public readonly dublicateVersion = this.baseUrl + '/under_writer/versions/duplicate/';
    public readonly archiveVersion = this.baseUrl + '/under_writer/versions/archive/';
    public readonly uwEmail = this.baseUrl + '/under_writer/email-uw/';
    public readonly getDTReportJson = this.baseUrl + '/sov_wizard/submission/retrieve-data-transparency-report-json/';
    public readonly archiveSubmission = this.baseUrl + '/under_writer/submission/archive/';
    public readonly reportedConstructions = this.baseUrl + '/sov_wizard/reported-constructions/';
    public readonly reportedOccupancies = this.baseUrl + '/sov_wizard/reported-occupancy/';
    public readonly getCompanies = this.baseUrl + '/company/company-names/';
    public readonly generateInvoice = this.baseUrl + '/sov_wizard/generate-invoice-data/';
    public readonly updateStatus = this.baseUrl + '/under_writer/versions/update-status/';
    public readonly listCompanies = this.baseUrl + '/company/list-companies/';
    public readonly emailReplyList = this.baseUrl + '/under_writer/submission-reply-email-log/';
    public readonly getSTPSovsLists = this.baseUrl + '/stp/submission-stp/';
    public readonly postSovForSTPProcess = this.baseUrl + '/stp/upload-sov/';
    public readonly pendingVersions = this.baseUrl + '/under_writer/pending-versions/';
    public readonly pendingVersionAttachments = this.baseUrl + '/under_writer/pending-attachment/';
    public readonly approvePendingVersion = this.baseUrl + '/under_writer/pending-versions/approve-as-new-version/';
    public readonly approveAsControlNumber = this.baseUrl + '/under_writer/pending-versions/approve-as-new-account/';
    public readonly constOccPieChartData = this.baseUrl + '/reports/const-occ-pie-chart-data/';
    public readonly getPasswordRules = this.baseUrl + '/user/password_rules';
}

const ApiUrls = new Urls();

export default ApiUrls;
