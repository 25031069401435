import React, {useState, useEffect} from 'react';

// router
import { useNavigate, Link } from 'react-router-dom';

// Components
import {Button} from "Components/Button/Button";
import {Input} from "Components/Input/Input";
import {SpinnerLoader} from "Components/Loader/Loader";
import {TokenService} from "Libraries/TokenService";
import {Paths} from "Libraries/Route";
import { useDispatch, useSelector } from 'react-redux';
import {isLoginAction} from "Redux/Action";
import SwitchToggle from 'Components/SwitchToggle/SwitchToggle';

// Icons
import ApiRequest from "Services/ApiRequest";
import { Redirect } from 'Routes/Redirect';
import {PasswordField} from "Components/Password/Password";
import AuthWrapper from 'Hoc/AuthWrapper';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import Methods from 'Libraries/CommonMethodsUI';
import Constants from 'Libraries/Constants';

const LoginScreen:React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cookies, setCookie, removeCookie] = useCookies(['credentials']);

    const isLoggedIn = useSelector((state: any) => state['loginReducer'].isLoggedIn);

    const [loginCredentials, setLoginCredentials] = useState<{ email: string; password: string; }>({ email: '', password: '' });
    const [error, setError] = useState<{ email: string; password: string; }>({ email: '', password: '' });
    const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);

    useEffect(() => {
        if(cookies && Object.keys(cookies)?.length > 0 && cookies?.credentials){
            setLoginCredentials({ email: cookies?.credentials?.email, password: Methods.encryptDecryptPassword(cookies?.credentials?.password, false) });
            setRememberMe(true);
        }
    }, []);

    async function loginUser(): Promise<void> {
        const { email, password }  = loginCredentials;

        if(!email || !password) setError({ ...error, email: 'Please type the required fields.' });
        else{
            setIsLoginLoading(true);
            setError({email: '', password: ''});
            
            try {
                const res = await ApiRequest.signInRequest({email, password});

                toast.success('Logged in successfully'); 
                setIsLoginLoading(false);
                TokenService.setAccessToken(res.data?.token);
                TokenService.setRefreshToken(res.data?.refresh_token);
                dispatch(isLoginAction(res.data));
            } catch (err) {
                setIsLoginLoading(false);
            }            
        }
    };

    const doRememberMe = React.useCallback((flag: boolean) => {
        if((loginCredentials.email || loginCredentials.password) && flag){
          setCookie("credentials", 
            JSON.stringify({ email: loginCredentials.email, password: Methods.encryptDecryptPassword(loginCredentials.password, true) }), 
            { secure: true, expires: Methods.getTomorrowDate() }
          );
        } else {
          removeCookie("credentials");
        }
    
        setRememberMe(flag);
    }, [loginCredentials, removeCookie, setCookie]);

    if(isLoggedIn || TokenService.getAccessToken()){
        return <Redirect to={Paths.dashboard} />;
    }

    return (
        <AuthWrapper email={undefined} backForward={false}>
            <React.Fragment>
                <div className='px-7'>
                    <h6 className="font-semibold text-white text-center mb-6 text-xl">Get Started</h6>
                    <div className='flex items-center justify-center'>
                        <Button
                            onClick={() => navigate(Paths.signup)} id="" disabled={false} title="Sign Up"
                            className="flex w-full rounded-lg border border-white h-10 flex-col items-center max-w-[190px]
                            text-sm font-medium text-white focus:outline-none m-0 bg-transparent justify-center hover:opacity-60 transition-all duration-200"
                        />
                        <Button
                            onClick={() => navigate(Paths.signup)} id="" disabled={false} title="Signup as Underwriter"
                            className="hidden w-full rounded-lg border border-white h-10 flex-col items-center max-w-[190px]
                            text-sm font-medium text-white focus:outline-none m-0 bg-transparent justify-center hover:opacity-60 transition-all duration-200"                                            
                        />
                    </div>
                    <div className="relative my-6">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-white" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-10 bg-primary-blue text-sm text-white">Or continue with</span>
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <Input
                        id="email-field" name="email" type="email"
                        value={loginCredentials.email} autoComplete="off" placeholder="Enter Email"
                        onChange={(e: any)=> {
                            setLoginCredentials({ ...loginCredentials, email: e.target.value });
                            setError({ ...error, email: '' });
                        }}                        
                        className={`block w-full caret-white text-white appearance-none rounded-lg border border-white px-3 py-2 ${error.email ? 'border-red-500' : 'border-white'} 
                        placeholder-white bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                    />
                    { error.email && (
                        <div className="my-1 text-xs text-red-500">
                            {error.email}
                        </div>
                    )}
                </div>

                <div className="mb-4">
                    <PasswordField
                        id={'password-field'} name={'password'}
                        value={loginCredentials.password} placeholder="Password"
                        onChange={(e: any)=> {
                            setLoginCredentials({ ...loginCredentials, password: e.target.value });
                            setError({ ...error, password: '' });
                        }}
                        keyPress={(e: any) => e.key === 'Enter' && loginUser()} autoComplete="off"
                        className={`block caret-white w-full text-white appearance-none rounded-lg border border-white px-3 py-2 ${error.password ? 'border-red-500' : 'border-white'} 
                        placeholder-white bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                        hidePasswordInfo={true}
                    />
                    { error.password && (
                        <div className="my-1 text-xs text-red-500">
                            {error.password}
                        </div>
                    )}
                </div>
                
                <div className="mb-4 flex items-center justify-between">
                    <SwitchToggle title="Remember me" enabled={rememberMe} setEnabled={(flag: boolean) => doRememberMe(flag)} />
                    <Link className="text-sm text-red-500 hover:opacity-60 transition-all duration-200" to={Paths.forgotPassword}>Forgot Password?</Link>
                </div>
                
                <Button
                    onClick={()=> loginUser()} id={'login-submit-btn'} disabled={isLoginLoading}
                    className="flex w-full rounded-lg border border-transparent h-10 flex-col items-center
                    text-sm font-medium text-white focus:outline-none m-0 bg-primary-skyblue justify-center hover:bg-opacity-60 transition-all duration-200"
                    title={isLoginLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Login'}                    
                />
            </React.Fragment>
        </AuthWrapper>        
    )
};

export default LoginScreen;
