import React, { useEffect, useState, FC } from "react";

import GoogleMapReact from "google-map-react";
import Methods from "Libraries/CommonMethodsUI";
import { IMapMarker } from "Libraries/Interfaces";

interface IGoogleMap {
  markers: IMapMarker[];
  mapHeight: string;
}

const GoogleMap: FC<IGoogleMap> = ({ markers, mapHeight }) => {

  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number; }>({ lat: 0, lng: 0 });
  const [defaultZoom, setdefaultZoom] = useState<number>(5);
  const [stepColorArray, setstepColorArray] = useState([]);
  const [mBounds, setmBounds] = useState();

  useEffect(() => {
    onInitialLoad();
  }, []);

  const onInitialLoad = (): void => {
    const filterEmptyLatLng = markers.filter(mark => mark.lat);
    setInitialData(filterEmptyLatLng);
    // findout max and min latLong coordinates 
    const latlist = filterEmptyLatLng.map((item: any) => item?.lat);
    const lnglist = filterEmptyLatLng.map((item: any) => item?.lng);
    let maxlat = Math.max(...latlist);
    let minlat = Math.min(...latlist);
    let maxlng = Math.max(...lnglist);
    let minlng = Math.min(...lnglist);
    // set initial zoom of Google map based on max and min coordinates
    getZoom(maxlat, maxlng, minlat, minlng);
    setMapCenter({ lat: minlat, lng: maxlng });
  };

  /**
   * this function calculates TIV for each marker ans sort it in ascending order.
   * based on these TIV, it defines the color of each marker using color value stepper.
   * example : hsl(${colorStep.step : 120}, 100%, 50%)
   * @param markers 
   */
  const setInitialData = (markers: any[]): void => {
    const totalCov = markers.map((item: any) => {

      if (item.coverages) {
        let a = item.coverages?.[0] ? item.coverages[0] : 0;
        let b = item.coverages?.[1] ? item.coverages[1] : 0;
        let c = item.coverages?.[2] ? item.coverages[2] : 0;
        let d = item.coverages?.[3] ? item.coverages[3] : 0;
        return a + b + c + d;
      } else {
        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;
        return a + b + c + d;
      }
    });
    let filteredArry = Methods.removeDublicatesInArray(totalCov);
    filteredArry.sort((a, b) => b - a);
    // create color value stepper 
    let stepValue = 120 / (filteredArry.length - 1);
    if (stepValue === Infinity) stepValue = 120;
    // define colors for each marker and construct the object array
    const mappedArry: any = filteredArry.map((item, idx) => {
      return { value: item, step: Math.round(stepValue * idx) }
    });
    setstepColorArray(mappedArry);
  };

  const latRad = (lat: number): number => {
    var sin = Math.sin(lat * Math.PI / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  // calculate map zoom level based on max latlong and min latlong, so that all the markers fit in single view 
  const getZoom = (lat_a: number, lng_a: number, lat_b: number, lng_b: number): void => {
    let latDif = Math.abs(latRad(lat_a) - latRad(lat_b));
    let lngDif = Math.abs(lng_a - lng_b);

    let latFrac = latDif / Math.PI;
    let lngFrac = lngDif / 360;

    let lngZoom = Math.log(1 / latFrac) / Math.log(2);
    let latZoom = Math.log(1 / lngFrac) / Math.log(2);
    setdefaultZoom(lngZoom > latZoom ? latZoom + 2 : lngZoom + 2);
  };

  const getMarkerIcon = (colorStep: any, _maps: any, scale: number) => {
    return {
      path: "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z",
      fillColor: `hsl(${colorStep ? colorStep.step : 120}, 100%, 50%)`,
      class: "noooooo",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: `hsl(${colorStep ? colorStep.step : 120}, 100%, 5%)`,
      rotation: 0,
      scale: scale,
      anchor: new _maps.Point(15, 30),
    };
  };

  const getMarkerContent = (marker: any, confidence: string | undefined): string => {
    const tiv: number = Methods.arrayValuesSum(marker.coverages);

    let _cova: number = marker.coverages[0] ? Math.ceil(marker?.coverages[0]) : 0;
    let _covb: number = marker.coverages[1] ? Math.ceil(marker?.coverages[1]) : 0;
    let _covc: number = marker.coverages[2] ? Math.ceil(marker?.coverages[2]) : 0;
    let _covd: number = marker.coverages[3] ? Math.ceil(marker?.coverages[3]) : 0;

    const locatnName = marker.locName[0] ? `
    &nbsp;&nbsp;<strong>Location Name: </strong>${marker.locName[0]}`
      : "";

    return `<div className="info-window">
      <p style="margin-top:0; margin-bottom: 4px"><strong>ID: </strong>${marker.id[0]}${locatnName}</p>
      
      <p style="margin-top:0; margin-bottom: 4px"><strong>Address: </strong>${marker.data ? marker.data.join(', ') : 'Unknown'}, ${marker.state[0]} ${marker.postal[0]}</p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>County: </strong>${marker.county[0] ? marker.county[0] : 'Unknown'},&nbsp;&nbsp;&nbsp;<strong>Couty Tier: </strong>${marker.countyTier[0] ? marker.countyTier[0] : 'Unknown'}</p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>Coverages:</strong> $${Methods.numberWithCommas(_cova)} (Building Limit); $${Methods.numberWithCommas(_covb)} (Other Limit); $${Methods.numberWithCommas(_covc)} (Contents Limit); $${Methods.numberWithCommas(_covd)} (BI Limit);</p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>TIV:</strong> $ ${Methods.numberWithCommas(tiv)} </p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>Construction:</strong> ${marker.constructCode[0] ? marker.constructCode[0] : "Unknown"}&nbsp;&nbsp;<strong>Occupancy:</strong> ${marker.occupancyCode[0] ? marker.occupancyCode[0] : "Unknown"}</p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>Number of Stories:</strong> ${marker.noOfStories[0] ? marker.noOfStories[0] : "Unknown"}&nbsp;&nbsp;<strong>LocPerils:</strong> ${marker.locPerils[0] ? marker.locPerils[0] : "Unknown"}</p>
      <p style="margin-top:0; margin-bottom: 4px"><strong>Year Built:</strong> ${(marker.yearBuilt[0] || marker.yearBuilt[0] === 0) ? marker.yearBuilt[0] : "Unknown"}</p>
      <p style="margin-top:0; margin-bottom: 4px"><strong>Floor Area:</strong> ${(marker.floorArea[0] || marker.floorArea[0] === 0) ? marker.floorArea[0].toLocaleString('en-US') : "Unknown"}&nbsp;&nbsp;<strong>Geocode Confidence:</strong> ${confidence ? confidence : marker.geoConfidance}</p> 
    </div>`
  };

  // once map is loaded, sets marker and its infowindow on google map
  const isMapLoaded = (map: any, isAddResetButton: boolean): void => {
    const _maps = (window as any).google.maps;
    const bounds = new (window as any).google.maps.LatLngBounds();

    markers.filter(f => f.lat && f.lng).map((marker: any, index: number) => {
      bounds.extend({ lat: marker.lat, lng: marker.lng });
      const tiv: number = Methods.arrayValuesSum(marker.coverages);
      let colorStep = stepColorArray.find((item: any) => item.value === tiv);
      const svgMarker = getMarkerIcon(colorStep, _maps, 0.6);

      let infoWindow = new _maps.InfoWindow({
        content: getMarkerContent(marker, undefined),
      });

      let mark = new _maps.Marker({
        position: { lat: marker.lat, lng: marker.lng },
        map, draggable: false, icon: svgMarker,
      });

      _maps.event.addListener(map, "click", function() {
        infoWindow.close();
      });

      mark.addListener("click", () => {
        mark.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
        infoWindow.open({
          anchor: mark,
        });
      });

      let lastDblClickTime = 0;
      // const doubleClickThreshold = 5000; // 500 milliseconds

      // Double click event listener
      mark.addListener("dblclick", () => {
        const currentTime = Date.now();

        if (currentTime - lastDblClickTime < currentTime) {
          // Second double-click detected
          setdefaultZoom(5);
          map.fitBounds(mBounds);
        } else {
          // First double-click detected
          infoWindow.close();
          setdefaultZoom(18);
          setMapCenter({ lat: markers[index].lat, lng: markers[index].lng });
        }

        lastDblClickTime = currentTime;
      });

      mark.addListener("mouseover", () => {
        mark.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
        mark.setIcon(getMarkerIcon(colorStep, _maps, 0.8));
      });

      mark.addListener("mouseout", () => {
        mark.setIcon(svgMarker);
      });

      mark.addListener("dragend", async () => {
        await onMarkerDrag(marker.id[0] - 1, mark.getPosition().lat(), mark.getPosition().lng());
        infoWindow.close();

        mark.addListener("click", () => {
          infoWindow.setContent(getMarkerContent(markers[index], 'User Supplied'));
          infoWindow.open(_maps, mark);
          setdefaultZoom(18);
        });
      });

      return mark;
    });

    setmBounds(bounds);
    map.fitBounds(bounds);

    if (isAddResetButton) {
      // Create the DIV to hold the control.
      const centerControlDiv = document.createElement('div');
      // Create the control.
      const centerControl = resetButton(map);
      // Append the control to the DIV.
      centerControlDiv.appendChild(centerControl);

      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
    }
  };

  // on marker dragged on the map get its current latlong and update on the sheet also it marks it as user supplied.
  async function onMarkerDrag(indexNum: number, lat: number, lng: number) {

  };

  function resetButton(map: any) {
    const controlButton = document.createElement('button');
  
    // Set CSS for the control
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '3px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '16px';
    controlButton.style.lineHeight = '38px';
    controlButton.style.margin = '2px 9px 2px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';
    
    // Set the icon for the button (using an SVG or an image URL)
    controlButton.innerHTML = '<img src="https://static.thenounproject.com/png/3648091-200.png" alt="Reset" style="width: 28px; height: 28px;">';
    
    controlButton.title = 'Click to recenter the map';
    controlButton.type = 'button';
  
    // Setup the click event listeners: simply set the map to the desired center
    controlButton.addEventListener('click', () => {
      isMapLoaded(map, false);
      // setdefaultZoom(5);
      // map.fitBounds(mBounds);
    });
  
    return controlButton;
  }

  const defaultMapOptions = {
    zoomControl: false,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: true,
    rotateControl: false,
    fullscreenControl: true,
  };

  return (
    <div className={`w-full relative ${mapHeight} gmap-container`}>
      <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY as string }}
          center={mapCenter}
          zoom={defaultZoom}
          options={defaultMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => isMapLoaded(map, true)}
          style={{ borderRadius: '6px' }}
      />
    </div>
  );
};

export default GoogleMap;
